import React, { Component } from 'react';
import { ToggleSwitch } from '../../Components/Elements/';
import { Link } from 'react-router-dom';
import { Read } from '../../controllers/Read';
import Error from '../../Components/Error/';
import { Button, Box, Paper, IconButton, TablePagination,Tooltip, Menu, MenuItem, TextField, styled, Table, TableBody, TableCell, TableContainer, TableRow, TableHead, makeStyles } from '@material-ui/core'
import FilterListIcon from '@material-ui/icons/FilterList';
import AddIcon from '@material-ui/icons/Add';
import {authContext} from '../../hooks/use-auth'
//------------------------------------------------------------------------------------------------
const TableHeadCell = styled(TableCell)({
	fontSize: '1rem',
	fontWeight: 600
})

export default class Users extends Component {
	static contextType = authContext
	constructor() {
		super();
		this.state = {
			users: [],
			depot: '',
			depotList: [],
			showFilter: false,
			filteredListItems: [],
			anchorEl: null,
			page:0,
			rowsPerPage:10,

		};
	}
	componentDidMount = async () => {
		const get = await Read('api/user');
		const getDepots = await Read(`api/user/allDepots`)
		if (getDepots) {
			this.setState({ depotList: getDepots });
		}
		if (get) {
			this.setState({ users: get, filteredListItems: get });
			//console.log(get)
			this.handleLoader(false);
		} else {
			this.handleLoader(false);
			this.setState({ error: true });
		}
	};
	handleLoader = n => {
		const { process } = this.props;
		//	return process(n);
	};
	toggleDepotListMenu = (event) => {

		this.setState({ showFilter: !this.state.showFilter, anchorEl: event.currentTarget })
	}
	onSelectDepot = (n) => {
		
		const { depotList, depot, showFilter, users } = this.state
		const f = users.filter((d) => d.depot === n)
		this.setState({ showFilter: !showFilter, filteredListItems: f, });
		this.handleClose()
	}
	handleClose = () => {
		this.setState({ anchorEl: null })
	};
	handleClick = (event) => {
	
		this.setState({  })
	};
	 handleChangePage = (event, newPage) => {
   
    this.setState({page:newPage})
  };

   handleChangeRowsPerPage = (event) => {
   this.setState({rowsPerPage:parseInt(event.target.value, 10),page:0})
  };
	render() {
		const { users, anchorEl, error, depotList, showFilter, filteredListItems,rowsPerPage,page } = this.state;
	    const {user} = this.context
		
		return (
			<>

				{error ? <Error msg={['Something went wrong, Please Try again']} /> : null}

				<Box>
					<Tooltip title='Add a user'>
						<Button component={Link} to="/admin/users/create" variant='contained' elevation={0} style={{ color: '#fff', backgroundColor: '#0e213ae0' }}>
							<AddIcon size='large' />



						</Button>
					</Tooltip>
					<Box style={{ float: 'right', position: 'relative' }}>
						<IconButton onClick={this.toggleDepotListMenu} aria-haspopup="true">
							<Tooltip title="Filter list by Depot">
								<FilterListIcon />
							</Tooltip>
						</IconButton>
						
						{
							user&&user.depot===1&&user.designation===1&&
							<Paper style={{ display: Boolean(anchorEl)?'block':'none' ,zIndex: 10, width: 150, padding: '.2rem', position: 'absolute', right: 20 }}>
							<Menu
								variant='menu'
								anchorEl={anchorEl}
								open={Boolean(anchorEl)}
								onClose={this.handleClose}
								
								>
								{depotList.map((d) => (
									<MenuItem key={d.id} value={d.name} onClick={this.onSelectDepot.bind(this,d.id)}>{d.name}</MenuItem>
								))}
							</Menu>
							</Paper>
							}

					</Box>
				</Box>
				<div className=''>
					<TableContainer>

						<Table>
							<TableHead>

								<TableRow>
									<TableHeadCell>Name</TableHeadCell>
									<TableHeadCell>Email</TableHeadCell>
									<TableHeadCell>Contact</TableHeadCell>
									<TableHeadCell>Depot</TableHeadCell>
									<TableHeadCell>Level</TableHeadCell>
									<TableHeadCell>Verified</TableHeadCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{filteredListItems
									 .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
									.map((u, i) => (
									<TableRow key={i}>
										<TableCell style={{ textTransform: 'capitalize' }}>{u.fullName}</TableCell>
										<TableCell>{u.email}</TableCell>
										<TableCell>{u.contact}</TableCell>
										<TableCell>{u.depotName}</TableCell>
										<TableCell style={{ textTransform: 'capitalize' }}>
											<Badge role={u.level} />
										</TableCell>
										<TableCell>
											<ToggleSwitch email={u.email} bool={u.verified} />
										</TableCell>
									</TableRow>
								))}

							</TableBody>
						</Table>
					</TableContainer>

					<TablePagination
                      rowsPerPageOptions={[10,25,50,100,250]}
                      component="div"
                      count={filteredListItems.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={this.handleChangePage}
                      onRowsPerPageChange={this.handleChangeRowsPerPage}
                    />
				</div>
			</>
		);
	}
}
function Badge({ role }) {
	const _role = role?.toLowerCase();
	function type() {

		switch (_role) {
			case 'admin':
				return 'badge-success'

			case 'approvals':
				return 'badge-warning'

			case 'invoicing':
				return 'badge-primary'

			default:
				return 'badge-info'

		}
	}
	return (
		<>

			<span className={`badge ${type()}`}>{_role === 'user' ? 'entry' : role}</span>



		</>
	)
}