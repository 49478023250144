const Alphabets = n => {
  const res = /^[a-zA-Z ]+$/.test(n) ? true : false;
  return res;
};
const Numeric = n => {
  const res = /^[0-9]+$/.test(n) ? true : false;
  return res;
};
const Dates = n => {
  try {
 new Date(n);  
      return true;
  } catch (error) {
    return false;
  }
};
const AlphaNumeric = n => {
  const res = /^[a-zA-Z0-9 ]+$/.test(n) ? true : false;
  return res;
};
const Email=(n)=>{
  
  const regx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(n)?true:false;//eslint-disable-line
return regx
}
const findValidateMethod = key => {
   let func = () => {};
   switch (key) {
     case "Alphabets":
       func =Alphabets;
       break;

     default:
       break;
   }
   return func;
 };
export { Alphabets, Numeric, Dates, AlphaNumeric, findValidateMethod,Email };
