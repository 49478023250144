import {Read} from "../../controllers/"

async function BRV(){
	let brv = await Read('api/brv')
	if(brv){
		brv=brv.map(b=>b.name)
		
		return brv
	}
		
		return false;
}
export { BRV };