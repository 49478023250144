import React, { Component } from 'react';
import ReactToPrint from 'react-to-print';
import Waybill from '../Waybill';
import Preloader from '../preloader/';
import ConfirmWindow from '../prompto/';
import { DeleteOne } from '../../controllers/Remove/DeleteOne';
//import {Read} from "../../controllers/Read/"
export default class PrintWaybill extends Component {
	constructor() {
		super();
		this.state = {
			load: true,
			del: false,
			doc: '',
			docId: 0,
			empty: false,
		};
	}
	componentDidMount = async () => {
		//const docId = this.props.match.params.docId;
		const docId = sessionStorage.getItem('__viewable_invoice')
		console.log(docId)
		this.setState({ docId });
		if (isNaN(docId)) {
			this.setState({ empty: true });
		}
	};
	handlePreloader = n => {
		this.setState({ load: n });
	};
	handleDeleteDialogue = async n => {
		const { doc, docId } = this.state;
		if (n) {
			const deleteWaybill = await DeleteOne(`api/fan-bill/remove/${doc}/${docId}`);
			if (deleteWaybill) {
				this.setState({ del: false });
				window.location.href = '/';
			} else {
				this.setState({ del: false });
			}
		} else {
			this.setState({ del: false });
		}
	};
	handleEmptyDialogue = () => {
		this.props.history.push('/');
	};
	deleteWaybill = () => {
		this.setState({ del: true });
	};
	render() {
		const { load, del, docId, empty } = this.state;
		return (
			<div className="TjkG3">

				
				<Preloader load={load} />
				{empty === true ? (
					<ConfirmWindow action={this.handleEmptyDialogue} msg={"Sorry, you don't have a waybill record"} />
				) : null}
				{docId !== 0 ? (
					<div>
						{del ? (
							<ConfirmWindow
								action={this.handleDeleteDialogue}
								msg={'Please confirm to delete waybill'}
							/>
						) : null}

						{/* <button  onClick={this.deleteWaybill} type="button" className="delete-waybill-btn">&times;</button> */}
					</div>
				) : null}
				<ReactToPrint
					trigger={() => {
						return (
							<div className="yuOp2" style={{ width: '100vw' }}>
								{docId !== 0 ? (
									<button className="submitBtn" type="submit">
										PRINT
									</button>
								) : null}
							</div>
						);
					}}
					content={() => this.componentRef}
				/>
				<div className="container" style={{maxWidth:'98%'}}>
					{docId !== 0 ? (
						 <Waybill docId={docId} process={this.handlePreloader} ref={el => (this.componentRef = el)} />
					
					) : null}
				</div>
			</div>
		);
	}
}
