export  function Input({ name, lbl, type, handler }) {
	function inputHandler(e) {
		return handler(e.target.name, e.target.value);
	}
	return (
		<div className="fieldEl">
			<div>
				<label>{lbl}</label>
			</div>
			<input type={type} name={name} onChange={inputHandler} />
		</div>
	);
}
export function Select({ name, lbl, type, data, handler }) {
	function selectHandler(e) {
		return handler(e.target.name, e.target.value);
	}
	return (
		<div className="fieldEl">
			<div>
				<label>{lbl}</label>
			</div>

			<select name={name} onChange={selectHandler}>
				<option value="">SELECT</option>
				{data.map((d, i) => (
					<option key={i} value={d}>
						{d}
					</option>
				))}
			</select>
		</div>
	);
}
export function DateInput(){
	
}