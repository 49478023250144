import React from 'react';
import { Link } from 'react-router-dom';
import { isLoggedIn, logOut } from '../../Auth';
export default function MenuList({ toggle }) {
	return (
		<div className="mlistContainer">
			<div className="mlistWrapper">
				<dl onClick={toggle}>
					<dd className="ddForMenu usr-info">
						<Link to={`/`} className="menuItemLink a">
							Home
						</Link>
					</dd>
					{!isLoggedIn() ? (
						<dd className="ddForMenu usr-info">
							<Link to={`/login`} className="menuItemLink a">
								Sign In
							</Link>
						</dd>
					) : (
						<React.Fragment>
							{/*userDetail().level !== 'admin' ? (
								<dd className="ddForMenu usr-info">
									<Link to={`/waybill`} className="menuItemLink a">
										Print
									</Link>
								</dd>
							) : null*/}
							<dd className="ddForMenu usr-info">
								<button to={`/sign-out`} onClick={logOut} className="menuItemLink btn-logout">
									Log out
								</button>
							</dd>
						</React.Fragment>
					)}
				</dl>
			</div>
			<div className="sideForClose" onClick={toggle}></div>
		</div>
	);
}
