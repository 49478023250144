//name of collection or table
import axios from "axios";
import API from "../API"
import {logOut} from "../../Auth"
const Update = async (ENDPOINT, data) => {
  try {
    const create = await axios.put(`${API()}/${ENDPOINT}`,data);
    if (create) {
      return true;
    }else{
       return false;
    }
   
  } catch (error) {
   // console.log(error);
    if(error.response.status ==="401"){
        logOut();
    }
    return false;
  }
};
export default Update