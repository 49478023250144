import React from 'react';
function Preloader({load}){
	return (
		<React.Fragment>
		{load===true?
		<div className="custom-preloader">
			<div className="slider">
			  <div className="line"></div>
			  <div className="subline inc"></div>
			  <div className="subline dec"></div>
			</div>
		</div>: null}
		</React.Fragment>
		)
}
export default Preloader;