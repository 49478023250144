import * as actions from '../actions/actionTypes';
const initialState = {}
const notificationReducer=(state=initialState,action)=>{
    switch (action.type) {
        case actions.UNREAD_NOTIFICATIONS:
            return {
                ...action.payload.notifications
                
            };
        default:
            return state;
           
    }
}
export default notificationReducer