import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
//import Form from './Components/form';
import './utils/scss/style.scss';
import './scss/style.scss';
//import Report from "./Admin/Report/"
import IdleTimer from './IdleTimer';
import Axios from 'axios';
import { createTheme, CssBaseline, ThemeProvider, CircularProgress } from "@material-ui/core";
import redirect from './redirects'
import { useAuth } from './hooks/use-auth'
import AppRouter from './router'
import DesignationPicker from './designationPicker';
Axios.defaults.withCredentials = true;
//-----------------------------------------------------------------------
export default function App() {
	const [isTimeout, setIsTimeout] = useState(false);
	const { user, signout } = useAuth()
	useEffect(() => {

		/*	let timer;
			localStorage.removeItem('_expiredTime');
			if (user) {
				timer = new IdleTimer({
					timeout: 1200, //expire after  seconds /1200 as 20mins of inactivity set
					onTimeout: () => {
						setIsTimeout(true);
					},
					onExpired: () => {
						//do something if expired on load
						setIsTimeout(true);
					},
				});
			}
			return () => {
				timer.cleanUp();
			};*/
	}, []);
	const theme = createTheme({});
    const designation = sessionStorage.getItem('__apd_operation_type')
	return (
		<ThemeProvider theme={theme}>
			<CssBaseline />
			{
				user &&
				<>
					{
						user.depot === 1 ?
							<Router>
								{isTimeout === true ? signout() : null}
								{ <AppRouter />}

								{/*user.level === 'user' && !designation && <DesignationPicker />*/}

							</Router>
							: redirect()
					}
				</>
			}
		</ThemeProvider>
	);

}
