//name of collection or table
import axios from "axios";
import API from "../API"
import {logOut} from "../../Auth"
const Create = async (ENDPOINT, data) => {
  try {
    const create = await axios.post(`${API()}/${ENDPOINT}`,data);
    if (create) {
      return create.data;
    }
    return false;
  } catch (error) {
   
    if(error.response?.status===401){
      logOut()
    }
    return false;
  }
};
export default Create