import React from 'react';
import toast, { Toaster } from 'react-hot-toast';
import {Link} from 'react-router-dom'
export default function NotificationCreate({...props}) {
    React.useEffect(() => {
        notify();
        return(()=>{
            toast.dismiss()
        })
     }, [])
     
    const infoTypeGenerator=()=>{
        let str =''
      if(props.type==='created'){
        str='has been added '
      }else if(props.type==='approved'){
        str='has been approved'
      }else if(props.type==='printed'){
        str = 'was printed'
      }
      return str
    }
    const notify = () => toast.loading(
        (t) => (
            <div>
                <div>
             <Link to='/admin/records'>{props.info} </Link> {infoTypeGenerator()}
    
                    <button className='' style={{ border: 0, backgroundColor: 'transparent', marginLeft: 50 }} onClick={() => toast.dismiss(t.id)}>
                        <i className='fa fa-times fa-md' style={{ color: 'grey' }}></i>
                    </button>
                </div>
            </div>
        ),
        {
            icon: (props.type==='created'?<i className='fa fa-info-circle fa-lg'  style={{color:'orange'}}></i>:
            <i className='fa fa-check-circle fa-lg' style={{color:'green'}}></i>)
        }
    );
   
    return (
        <>
        
            <Toaster  position="bottom-right"/>
           
        </>
    )
}