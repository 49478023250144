import React from 'react'
import Sidebar from './Sidebar';
import Header from '../../Components/header';
import {useSelector} from 'react-redux'
//-------------------------------------------------
import { Grid, Container, Card, CardContent, styled } from '@material-ui/core'
//--------------------------------------------------

const CardWrapper = styled(Card)({
    margin: 10,
    height: '85vh',
    minWidth: '100%',
    overflow:'scroll',
    marginBottom:100
})

export default function Layout({ children }) {
    const notifs = useSelector(state=>state.notifications)
    //header & sidebar & outlet
    
    return (
        <>
            <Container maxWidth='xl' style={{ padding: 0, height: '100vh',overflow:'hidden', backgroundColor: '#f1f1f1', marginTop: 'auto' }}>
                <Grid container >
                    <Grid item xs={12}>
                        <Header notifications={notifs}/>
                    </Grid>
                    {/**sidebar */}
                    <Grid item >
                        <Sidebar />
                    </Grid>
                    <Grid item style={{ width: 'calc(100vw - 275px)' }}>
                        <CardWrapper variant='outlined'>
                            <CardContent>
                                {children}
                            </CardContent>
                        </CardWrapper>
                    </Grid>
                </Grid>
            </Container>

        </>
    )
}
