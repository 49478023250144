import jwt_decode from "jwt-decode";
import API from "../controllers/API"
import axios from "axios";
export async function login(email,password){
	
	try{
		 const signIn = await axios.post(`${API()}/api/user/login`,{email,password})
        if(signIn){
            localStorage.setItem('AuthToken',signIn);
            //check user access level

            const user = userDetail();
            if(user !== false){
            	return user.level
            }
        }else{
        	return false
        }
    }catch(err){
    	console.log(err)
    	if(err.response){
    		return err.data
    	}else{
    		return "Operation failed"
    	}
    }
}
export function userDetail(){
	const token = localStorage.getItem('AuthToken');
	if(token){
		const d = jwt_decode(token);
		const obj ={
			 name: d.fullName,
			 email :d.email,
			 level:d.level.toLowerCase(),
			 uid: d.uid
		}
		return obj}
	else{
		return false
	
	}
	
}
export function manageAccesslevel(){
	const _u = userDetail();
	if(_u){
		if(_u.level.toLowerCase() === "admin"){
			window.location.href="/admin"
			return {level:"admin"}
		}else{
			window.location.href = "/"
			return {level:"user"}
		}
	}
}
export function isLoggedIn(){
	const token = localStorage.getItem('AuthToken');
	if(token){
		return true
	}else{
		return false
	}
}
export function verifyUser(){

}
export function logOut(){
	const token = localStorage.getItem('AuthToken');
	 localStorage.removeItem("_expiredTime");

	if(token){
		localStorage.removeItem('AuthToken');
		window.location.href = "/"
	}
}
export function authStatus(){
	
}


export const Auth = {
  isAuthenticated: isLoggedIn(),
  level: userDetail()?.level
  
}
