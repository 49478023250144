import axios from 'axios';
import API from '../controllers/API'
import redirect from '../redirects';
//------------------------------
const baseURL = `${API()}/`
const httpAxios = axios.create({
    baseURL: process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API : 'http://localhost:3030/',
    withCredentials: true,
});
httpAxios.interceptors.response.use((res) => {
    return res
}, (error) => {
    // console.log(error.response.status)
    if (error.response && error.response.status === 401) {
        return redirect()
    }
    return Promise.reject(error);
}

)
export default httpAxios