import React, { Component } from "react";
import { Read, Create } from "../../controllers/";
//import { Input, Select } from '../../Components/Elements/form-input/input';
import { BRV } from "../../Components/Elements/Constants";
import Error from "../../Components/Error/";
import { FormatDate } from "../../Helpers/Dates";
import { CSVLink } from "react-csv";
import { DeleteOne } from "../../controllers/Remove/DeleteOne";
import { Link, Navigate, useNavigate } from "react-router-dom";
import ConfirmWindow from "../../Components/prompto";
import FilterModal from "../filter";


//-----------------------------------------------------------
import { styled,Table, TableBody, TableCell,TablePagination, TableContainer, TableRow, TableHead, Typography,CircularProgress } from '@material-ui/core'
import { authContext } from "../../hooks/use-auth";

//-----------------------------------------------------------------

const TableHeadCell=styled(TableCell)({
    fontSize:'1rem',
    fontWeight:600
})
export default class MuiReport extends Component {
    static contextType = authContext;
    constructor() {
        super();
        this.state = {
            records: [],
            dateFrom: null,
            dateTo: null,
            status: "",
            product_type: "",
            bdc: "",
            GRAND_TOTAL: 0,
            VOLAT20_TOTAL: 0,
            VOL_OBS: 0,
            fileName: "",
            csvData: [],
            stamp: 0,
            fanBillNum: "",
            checked: "",
            currUser: "",
            deleteDialog: false,
            paginate: null,
            brvs: [],
            volume_obs: "",
            owner: "",
            recieving_depot: "",
            transporter: "",
            transporterList: [],
            OWNERS: [],
            isSearching:false,
            rowsPerPage:25,
            page:0,
        };
    }
    //PAGINATION

    componentDidMount = async () => {
        //console.log(this.props)
        const usr = this.context.user
        // console.log(usr)
        const epoch = Math.round(Date.now() / 1000);
        this.setState({ stamp: epoch, currUser: usr.uid,isSearching:true });
        const _OWNERS = await Read("api/bdc/");
        if (_OWNERS) {
            let v = _OWNERS.map((b) => b.name);
            this.setState({ OWNERS: v });
        }
        const transporters = await Read("api/transporters");
        if (transporters) {
            //console.log(transporters)
            let v = transporters.map((b) => b.name);
            this.setState({ transporterList: v });
        }

        let getRecords = await Read("api/fan-bill/approved");

        if (getRecords) {
            //setting
            //console.log(getRecords)
            let records = getRecords;
            let tmp1 = 0;
            let tmp2 = 0;
            let v2 = getRecords.map((t) => t.volumeAt20);
            let tt = getRecords.map((t) => t.total);

            for (let i = 0; i < v2.length; i++) {
                tmp1 += parseFloat(v2[i]);
            }
            for (let i = 0; i < tt.length; i++) {
                tmp2 += parseFloat(tt[i]);
            }
            const brvs = await BRV();

            this.setState({ records, GRAND_TOTAL: tmp2, VOLAT20_TOTAL: tmp1, brvs,isSearching:false });
            //console.log(records);

            // GRAND_TOTAL: tmp2, VOLAT20_TOTAL: tmp1
            /*	this.setState({ records: getRecords, GRAND_TOTAL: tmp2, VOLAT20_TOTAL: tmp1 });
             */
            this.preloaderProcess(false);
        } else {
            this.setState({ err: true });
        }

        //prepare data for excel export
        this.getCsvData();
    };
    calcTotals = () => {
        const { records } = this.state;
        let v2 = records.map((t) => t.volumeAt20);
        let tt = records.map((t) => t.total);
        let tmp1 = 0;
        let tmp2 = 0;
        for (let i = 0; i < v2.length; i++) {
            tmp1 += parseFloat(v2[i]);
        }
        for (let i = 0; i < tt.length; i++) {
            tmp2 += parseFloat(tt[i]);
        }
        this.setState({ GRAND_TOTAL: tmp2, VOLAT20_TOTAL: tmp1 });
    };
    preloaderProcess = (n) => {
        let { process } = this.props;
        return
        //return process(n);
    };
    handler = (m, n) => {
        //console.log(m,n)
        this.setState({ [m]: n });
    };

    onSubmitFilter = async (e) => {
        e.preventDefault();
        this.setState({
            dateFrom: null,
            dateTo: null,
            brv_num: "",
            status: "",
            product_type: "",
            isSearching:true
        });
        this.preloaderProcess(true);
        const epoch = Math.round(Date.now() / 1000);
        this.setState({ stamp: epoch });
        const {
            dateFrom,
            dateTo,
            brv_num,
            product_type,
            transporter,
            recieving_depot,
            volume_obs,
            fanBillNum,
            owner,
        } = this.state;
        /*  console.log(
                        dateFrom,
                        dateTo,
                        brv_num,
                        status,
                        product_type,
                        transporter,
                        recieving_depot,
                        volume_obs,
                        fanBillNum,
                        owner
                  );*/

        const post = await Create("api/fan-bill/filter", {
            dateFrom,
            dateTo,
            brv_num,
            product_type,
            status: "approved",
            transporter,
            recieving_depot,
            volume_obs,
            owner,
        });
        //  console.log(post," => res")
        if (post) {
            let v2 = post.map((t) => t.volumeAt20);
            let tt = post.map((t) => t.total);
            let tmp1 = 0;
            let tmp2 = 0;
            for (let i = 0; i < v2.length; i++) {
                tmp1 += parseFloat(v2[i]);
            }
            for (let i = 0; i < tt.length; i++) {
                tmp2 += parseFloat(tt[i]);
            }

            this.setState({ records: post, GRAND_TOTAL: tmp2, VOLAT20_TOTAL: tmp1 ,isSearching:false});
            this.preloaderProcess(false);

        } else {
            this.setState({ err: true,isSearching:false });
        }
    };

    getCsvData = () => {
        const csvData = [
            [
                "DATE",
                "TRANSACTION #",
                "BRV #",
                "OWNER",
                "TRANSPORTER",
                "PRODUCT",
                "VOLUME@20 (ℓ)",
                "VOLUME OBS. (ℓ)",
                "RECEIVING DEPOT",
                "DRIVER",
                "METER/ARM #",
                "LOADING DEPOT",

                "DENSITY@20 (g/ℓ)",
                // "VCF",
                "SUPERVISOR",
                "APPROVED BY",
                "SEALER",
                "DIPPER",
                "TEMP(℃)",
                'RELEASING TANK',
                "REMARKS",
            ],
        ];
        const getRecords = this.state.records;
        const { GRAND_TOTAL, VOLAT20_TOTAL } = this.state;
        for (let i = 0; i < getRecords.length; i += 1) {
            const arr = [
                `${FormatDate(getRecords[i].record_date).date}`,
                `${getRecords[i].coll_order}`,
                `${getRecords[i].brv_num}`,
                `${getRecords[i].owner}`,
                `${getRecords[i].transporter}`,
                `${getRecords[i].product_type}`,
                `${getRecords[i].volumeAt20}`,
                `${getRecords[i].total}`,
                `${getRecords[i].recieving_depot}`,
                `${getRecords[i].driver}`,
                `${getRecords[i].meter_arm_num}`,
                `${getRecords[i].loading_depot}`,

                `${getRecords[i].litre_density_at_20}`,
                // `${getRecords[i].vcf}`,
                `${getRecords[i].supervisor}`,
                `${getRecords[i].approved_by}`,
                `${getRecords[i].sealer}`,
                `${getRecords[i].dipper}`,
                `${getRecords[i].litre_temp}`,
                `${getRecords[i].releasing_tank}`,
                `${getRecords[i].remarks}`,
            ];
            csvData.push(arr);
        }

        csvData.push([
            ``,
            ``,
            ``,
            ``,
            ``,
            ``,

            `${VOLAT20_TOTAL}`,
            `${GRAND_TOTAL}`,
            ``,
            ``,
            ``,
            ``,
            ``,
            ``,

            ``,
            ``,
            ``,
            ``,
            ``,
            ``,
        ]);

        return csvData;
    };
    invoicingStatus = (params) => {
        const s = params === "CLEARED" ? "approve-btn" : "btn-warning";
        return s;
    };
    handleDialog = async (e) => {
        if (e) {
            await this.del();
            await this.calcTotals();
        }
        this.setState({ deleteDialog: false });
    };
    showDialog = (e) => {
        this.setState({ deleteDialog: true, checked: e.target.value });
    };

    render() {
        const {
            OWNERS,
            deleteDialog,
            brvs,
            stamp,
            err,
            GRAND_TOTAL,
            VOLAT20_TOTAL,
            transporterList,
            isSearching,
            rowsPerPage,
            page,
            records
        } = this.state;
        const { user } = this.context
        return (
            <>

            <div>
                {isSearching&& <CircularProgress style={{height:30,width:30}}/>}
            </div>
              <div style={{ display: "block" }}>

                    <button
                        className="btn btn-info"
                        data-toggle="modal"
                        data-target="#modal-sm"
                    >
                        Filter
                        <span aria-hidden="true">
                            <i className="fas fa-filter"></i>
                        </span>
                    </button>
                    {deleteDialog ? (
                        <ConfirmWindow
                            msg="Confirm to delete selected row"
                            action={this.handleDialog}
                        />
                    ) : null}
                    &nbsp;

                    {err ? (
                        <Error
                            close={() => {
                                this.setState({ err: false });
                            }}
                            msg={["Something went wrong, Please Try again"]}
                        />
                    ) : null}
                    { }
                    <FilterModal
                        role={user.level}
                        func={this.handler}
                        brvs={brvs}
                        onSubmit={this.onSubmitFilter}
                        transporterList={transporterList}
                        owners={OWNERS}
                    />

                    <span style={{ color: "#888" }}>
                        Search results {`${this.state.records.length}`}
                    </span>
                    {this.state.records.length > 0 ? (
                        <ExportReactCSV
                            csvData={this.getCsvData()}
                            fileName={`BOST-APD-${stamp}.csv`}
                        />
                    ) : null}
                    <TableContainer>
                        <Table size='small' aria-label="a reports table">
                            <TableHead>
                                <TableRow>
                                    {user.level !== "viewer" ? (
                                       <TableHeadCell >ACTION</TableHeadCell>
                                    ) : null}
                                    <TableHeadCell >APPROVAL STATS</TableHeadCell>
                                    <TableHeadCell >DATE</TableHeadCell>
                                    <TableHeadCell >OWNER</TableHeadCell>
                                    <TableHeadCell >TRANSPORTER </TableHeadCell>
                                    <TableHeadCell >DRIVER</TableHeadCell>
                                    <TableHeadCell >BRV #</TableHeadCell>
                                    <TableHeadCell >PRODUCT TYPE</TableHeadCell>
                                    <TableHeadCell >METER/ARM #</TableHeadCell>
                                    <TableHeadCell >LOADING DEPOT</TableHeadCell>
                                    <TableHeadCell >RECEIVING DEPOT</TableHeadCell>
                                    {<TableHeadCell >VOLUME OBS(ℓ)</TableHeadCell>}
                                    {<TableHeadCell >TEMP(℃)</TableHeadCell>}
                                    <TableHeadCell >DENSITY @20(g/ml)</TableHeadCell>
                                    {/* <TableHeadCell >VCF</TableHeadCell> */}
                                    <TableHeadCell >VOLUME @20(ℓ)</TableHeadCell>
                                    <TableHeadCell >SUPERVISOR</TableHeadCell>
                                    <TableHeadCell >APPROVED BY</TableHeadCell>
                                    <TableHeadCell >SEALER</TableHeadCell>
                                    <TableHeadCell >DIPPER</TableHeadCell>
                                    <TableHeadCell>RELEASING TANK</TableHeadCell>
                                    <TableHeadCell >REMARKS</TableHeadCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>

                                {this.state.records
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((r, i) => (
                                    <TableRow key={i}>
                                        {user.level?.toLowerCase() !== "viewer" ? (
                                            <TableCell>
                                                {user.level?.toLowerCase() === "admin" ? (
                                                    <button
                                                        onClick={this.showDialog}
                                                        value={r.id}
                                                        className="xcYi3  disapprove-btn"
                                                    >
                                                        DELETE
                                                    </button>
                                                ) : null}
                                                {user.level !== "viewer" ? (
                                                    <InvoiceRedirector rowId={r.id} />
                                                ) : null}
                                            </TableCell>
                                        ) : null}
                                        {user.level === "admin" ? (
                                            <TableCell>
                                                <button className="xcYi3 approve-btn">
                                                    {r.status.toUpperCase()}
                                                </button>
                                            </TableCell>
                                        ) : null}
                                        {user.level === "invoicing" ? (
                                            <TableCell>
                                                <button
                                                    className={`xcYi3 ${this.invoicingStatus(
                                                        r.invoicingStatus
                                                    )}`}
                                                >
                                                    {r.invoicingStatus ? r.invoicingStatus : "PENDING"}
                                                </button>
                                            </TableCell>
                                        ) : null}
                                        {user.level?.toLowerCase() === "viewer" ? (
                                            <TableCell>
                                                <button
                                                    className={`xcYi3 ${this.invoicingStatus(
                                                        r.invoicingStatus
                                                    )}`}
                                                >
                                                    {r.invoicingStatus ? r.invoicingStatus : "PENDING"}
                                                </button>
                                            </TableCell>
                                        ) : null}
                                        <TableCell>{FormatDate(r.record_date).date}</TableCell>
                                        <TableCell>{r.owner.toUpperCase()}</TableCell>
                                        <TableCell>{r.transporter?.toUpperCase()}</TableCell>
                                        <TableCell>{r.driver?.toUpperCase()}</TableCell>
                                        <TableCell>{r.brv_num}</TableCell>
                                        <TableCell>{r.product_type}</TableCell>
                                        <TableCell>{r.meter_arm_num}</TableCell>
                                        <TableCell>{r.loading_depot}</TableCell>
                                        <TableCell>{r.recieving_depot}</TableCell>
                                        {<TableCell>{r.total?.toLocaleString("en")}</TableCell>}
                                        {<TableCell>{r.litre_temp}</TableCell>}
                                        <TableCell>{r.litre_density_at_20}</TableCell>
                                        {/* <TableCell>{r.vcf}</TableCell> */}
                                        <TableCell>{r.volumeAt20?.toLocaleString("en")}</TableCell>
                                        <TableCell>{r.supervisor?.toUpperCase()}</TableCell>
                                        <TableCell>{r.approved_by?.toUpperCase()}</TableCell>
                                        <TableCell>{r.sealer?.toUpperCase()}</TableCell>
                                        <TableCell>{r.dipper?.toUpperCase()}</TableCell>
                                        <TableCell>{r.releasing_tank}</TableCell>
                                        <TableCell>{r.remarks}</TableCell>
                                    </TableRow>
                                ))}

                            </TableBody>
                            <TableBody style={{position:'fixed',bottom:0,}}>
                                <TableRow>
                                    <TableCell> <strong>TOTAL VOLUME OBSERVED = </strong> <strong>{GRAND_TOTAL?.toLocaleString("en")}</strong></TableCell>
                                    
                                    <TableCell> <strong>TOTAL VOLUME@20 = </strong>  <b>{VOLAT20_TOTAL?.toLocaleString("en")}</b></TableCell>
                                   
                                </TableRow>
                               
                            </TableBody>
                        </Table>
                    </TableContainer>
                    
                    <TablePagination
                      rowsPerPageOptions={[25,50,100,250]}
                      component="div"
                      count={records.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={this.handleChangePage}
                      onRowsPerPageChange={this.handleChangeRowsPerPage}
                    />

                    
                </div>
            </>
        );
    }
 handleChangePage = (event, newPage) => {
   
    this.setState({page:newPage})
  };

   handleChangeRowsPerPage = (event) => {
   this.setState({rowsPerPage:parseInt(event.target.value, 10),page:0})
  };

    del = async () => {
        const { checked, currUser, records } = this.state;
        if (checked !== "" && currUser !== "") {
            const rem = await DeleteOne(`api/fan-bill/remove/${currUser}/${checked}`);
            if (rem) {
                const dl = records.filter((r) => {
                    if (r.id !== Number(checked)) {
                        return r;
                    }
                    return null;
                });
                //const index = records.findIndex()

                this.setState({ records: [...dl] });
            } else {
                this.setState({ err: true });
            }
        } else {
            this.setState({ err: true });
        }
    };
    handleCheck = (e) => {
        let { checked } = this.state;
        const val = checked === e.target.value ? "" : e.target.value;
        this.setState({ checked: val });
    };
}

function InvoiceRedirector({ rowId }) {
    const navigate = useNavigate();
    const onNavigate = () => {
        sessionStorage.setItem('__viewable_invoice', rowId)
        navigate(`/admin/doc/${rowId}`)
    }
    return (
        <>
            <button
                onClick={onNavigate}
                value={rowId}
                className="xcYi3 view-btn"
            >
                VIEW
            </button>

        </>
    )

}

export const ExportReactCSV = ({ csvData, fileName }) => {
    return (
        <div className="yuOp2">
            <button className="submitBtn to-excel-btn">
                <CSVLink data={csvData} filename={fileName}>
                    <img
                        src={`${process.env.PUBLIC_URL}/excel.png`}
                        alt="export to excel"
                    />
                </CSVLink>
            </button>
        </div>
    );
};
