import { AdminRoleGuard, EntryRoleGuard, FallbackGuard } from '../Components/Guard';
import MuiReport from '../Admin/Report/MuiReport';
import PrintWaybill from '../Components/PrintWaybill';
import Users from '../Admin/Users/Users';
import Create from '../Admin/Users/create';
import BackupData from '../Admin/Backup';
import Approvals from '../Approvals/'
import Dashboard from '../Admin/index';
import RecallFormEdit from '../Components/form/recall-form';
import Form from '../Components/form';
import MUIcreateUserForm from '../Admin/Users/MUIcreateUserForm'

import {useAuth} from '../hooks/use-auth'
//-----------------------------------------------
const designation = sessionStorage.getItem('__apd_operation_type')


const allowedRoutesForNonEntryRole = {
    // invoicing: ['admin/report', 'admin/doc'],
  
    admin:[
        { path: '', element: <AdminRoleGuard><Dashboard /></AdminRoleGuard> },
        { path: 'report', element: <AdminRoleGuard><MuiReport /></AdminRoleGuard> },
        { path: 'doc/:docId', element: <AdminRoleGuard><PrintWaybill /></AdminRoleGuard> },
        { path: 'users', element: <AdminRoleGuard><Users /></AdminRoleGuard> },
        { path: 'users/create', element: <AdminRoleGuard><MUIcreateUserForm /></AdminRoleGuard> },
        { path: 'backup', element: <AdminRoleGuard><BackupData /></AdminRoleGuard> },


    ],
    approvals: [
        { path: '', element: <AdminRoleGuard><Dashboard /></AdminRoleGuard> },
        { path: 'doc/:docId', element: <AdminRoleGuard><PrintWaybill /></AdminRoleGuard> },
        { path: 'approvals', element: <AdminRoleGuard><Approvals /></AdminRoleGuard> },

    ],
    viewer: [
        { path: '', element: <AdminRoleGuard><Dashboard /></AdminRoleGuard> },
        { path: 'report', element: <AdminRoleGuard><MuiReport /></AdminRoleGuard> },

    ],
    invoicing:[
        { path: '', element: <AdminRoleGuard><Dashboard /></AdminRoleGuard> },
        { path: 'report', element: <AdminRoleGuard><MuiReport /></AdminRoleGuard> },
        { path: 'doc/:docId', element: <AdminRoleGuard><PrintWaybill /></AdminRoleGuard> },

    ],
    user:[
        { path: '', element: <EntryRoleGuard><Form /></EntryRoleGuard> },
        { path: 'brv-recall/:docId', element: <EntryRoleGuard><RecallFormEdit /></EntryRoleGuard> },

    ]


}

const allowedRoutesForEntryRole ={
    user:[
        { path: '', element: <EntryRoleGuard><Form /></EntryRoleGuard> },
        { path: 'brv-recall/:docId', element: <EntryRoleGuard><RecallFormEdit /></EntryRoleGuard> },

    ]
}
export {allowedRoutesForNonEntryRole,allowedRoutesForEntryRole}