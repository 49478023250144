import React from "react"
export default function ConfirmWindow({msg,action}){
	
	function onConfirm(){
		return action(true)
	}
	function onCancel(){
		
		return action(false)
	}

	return(
		<React.Fragment>
		
			<div className="prompt">
				<div className="confirm">
					<p>{msg}</p>
					<div className="btns2">
						<button className="pos" onClick={onConfirm}>Confirm</button>
						<button  className="neg" onClick={onCancel}>Cancel</button>
					</div>
				</div>
			</div>
		</React.Fragment>
		)
}