import React, { Component } from 'react';
import { Read, Update } from '../controllers/';
import { userDetail } from '../Auth/';
import Error from './Error';
import { FormatDate } from '../Helpers/Dates';
import { apiURL } from '../urlConfig';
import { authContext } from '../hooks/use-auth';
const io = require('socket.io-client')
const socket = io.connect(apiURL,
	{	
		forceNew:true,
		reconnectionAttemps:15,
		reconnectionDelay:3000
	});

export default class Waybill extends Component {
	static contextType = authContext
	constructor() {
		super();
		this.state = {
			data: [],
			compartments: [],
			compartmentsReleased: [],
			product: '',
			total: 0,
			lTemp: 0,
			time: null,
			destination: '',
			volAt20: 0,
			driver: '',
			date: null,
			issuer: '',
			vcf: 0,
			lDensity: 0,
			omc: '',
			bdc: '',
			errs: [],
			TOP_SEAL: [],
			DOWN_SEAL: [],
			RELEASED: [],
			oH: [],
			DIP_ULLAGE: [],
			volObs: 0,
			owner:''
		};
	}

dynamicSetState = obj => {
	for (let index = 0; index < obj.length; index++) {
		for (let [k, v] of Object.entries(obj[index])) {
			this.setState({ [k]: [v] });
				//console.log(k, v);
		}
	}
};

	componentDidMount = async () => {
		const {user} = this.context;
		const uid = user?.uid;
		if (uid) {
			let get = await Read(`api/fan-bill/print/${this.props.docId}`);
			let releaseVals = [];
			let total = 0;
			let date = null;
			if (get.length > 0) {
				const stmt = get[0];
				const TOP_SEAL = {
					top_seal_1: stmt.top_seal_1,
					top_seal_2: stmt.top_seal_2,
					top_seal_3: stmt.top_seal_3,
					top_seal_4: stmt.top_seal_4,
					top_seal_5: stmt.top_seal_5,
					top_seal_6: stmt.top_seal_6,
					top_seal_7: stmt.top_seal_7
				};
				const DOWN_SEAL = {
					down_seal_1: stmt.down_seal_1,
					down_seal_2: stmt.down_seal_2,
					down_seal_3: stmt.down_seal_3,
					down_seal_4: stmt.down_seal_4,
					down_seal_5: stmt.down_seal_5,
					down_seal_6: stmt.down_seal_6,
					top_seal_7: stmt.down_seal_7
				};
				const RELEASED = {
					released_1: stmt.released_1,
					released_2: stmt.released_2,
					released_3: stmt.released_3,
					released_4: stmt.released_4,
					released_5: stmt.released_5,
					released_6: stmt.released_6,
					released_7: stmt.released_7,
				};

				const oH = {
					oh_1: stmt.oh_1,
					oh_2: stmt.oh_2,
					oh_3: stmt.oh_3,
					oh_4: stmt.oh_4,
					oh_5: stmt.oh_5,
					oh_6: stmt.oh_6,
					oh_7: stmt.oh_7,
				};
				const DIP_ULLAGE = {
					dip_ullage_1: stmt.dip_ullage_1,
					dip_ullage_2: stmt.dip_ullage_2,
					dip_ullage_3: stmt.dip_ullage_3,
					dip_ullage_4: stmt.dip_ullage_4,
					dip_ullage_5: stmt.dip_ullage_5,
					dip_ullage_6: stmt.dip_ullage_6,
					dip_ullage_7: stmt.dip_ullage_7,
				};
				this.dynamicSetState([{ TOP_SEAL, DOWN_SEAL, RELEASED, oH, DIP_ULLAGE }]);
				//const val = JSON.parse(get[0].compartmentReleased);
				//const released = v.map((r)=>)
				/*
			for (let v of Object.values(val)) {
				if(v!==0){
						total += parseFloat(v);
						releaseVals.push(v);
				}
			
			}*/

				date = get[0].record_date;

				const dateTime = FormatDate(date);
				this.setState({
					data: [get[0]],
					compartments: JSON.parse(get[0].compartments),
					compartmentsReleased: releaseVals,
					product: get[0].product_type,
					total,
					date: dateTime.date,
					volAt20: get[0].volumeAt20,
					volObs: get[0].total,
					driver: get[0].driver,
					lTemp: get[0].litre_temp,
					issuer: get[0].issuer,
					destination: get[0].recieving_depot,
					time: dateTime.time,
					vcf: get[0].vcf,
					lDensity: get[0].litre_density_at_20,

					fan_num: get[0].fan_num,
					brv: get[0].brv_num,
					coll_order: get[0].coll_order,
					transporter: get[0].transporter,
					loading_depot: get[0].loading_depot,
					presence_of_water: get[0].presence_of_water,
					hFore: get[0].hFore,
					hAfter: get[0].hAfter,
					owner:get[0].owner
				});

				//update viewed status
				if(user.level.toLowerCase()==='approvals'){
					const hasViewed = await Update(`api/fan-bill/view/${this.props.docId}`);
				if (hasViewed) {
				}
				}
				
				if (user.level.toLowerCase() === 'invoicing') {
					//hook socket
					socket.emit('get-notifications',{CLEARED:true})
					const upt = await Update(`api/views/invoicing/${this.props.docId}`, { invoicingStatus: 'CLEARED' });
					if (upt) {
					}
				}
				this.handleLoader(false);
			} else {
				this.handleLoader(false);
				this.setState({ errs: ['An error occurred'] });
			}
		} else {
			alert('Something went wrong');
			this.handleLoader(false);
			this.setState({ errs: ['An error occurred'] });
		}
	};
	handleLoader = n => {
		const { process } = this.props;
		return process(n);
	};
	onCloseErr = () => {
		this.setState({ errs: [] });
	};
	render() {
		const {
			errs,
			coll_order,
			fan_num,
			brv,
			data,
			vcf,
			product,
			lDensity,
			date,

			issuer,
			lTemp,
			destination,
			volAt20,
			driver,
			loading_depot,
			presence_of_water,
			hFore,
			hAfter,
			TOP_SEAL,
			DOWN_SEAL,
			RELEASED,
			oH,
			volObs,
			transporter,
			DIP_ULLAGE,owner
		} = this.state;
		//	console.log(destination);

		return (
      <div className="pdf-templater d-flex justify-content-center align-items-center">
        <div style={{ width: "800px", marginTop: 80 }}>
          <div className="">
            {errs.length > 0 ? (
              <Error msg={errs} close={this.onCloseErr} />
            ) : null}

            <div className="">
              <div className="pdf-headers-chd"></div>
            </div>
            <div className="d-flex mb-50">
              <table className="table table-borderless">
                <tbody>
                  <tr>
                    <td
                      className="text-center pb-0"
                      style={{ fontSize: "2.2em" }}
                    >
                      {transporter?.toUpperCase()}
                    </td>
                  </tr>
                  <tr>
                    <th className="text-center pt-30">
                      <div
                        className=""
                        style={{
                          paddingLeft: "40px",
                          marginTop: 20,
                          fontSize: "1.4em",
                        }}
                      >
                        {coll_order}
                      </div>
                    </th>
                  </tr>
                </tbody>
              </table>
              <table className="table table-borderless">
                <tbody className="h-top-2">
                  <tr>
                    <th className="text-right f1 pt-15">
                      <div
                        className="mr-20"
                        style={{ paddingRight: "20px", fontSize: "1.4em" }}
                      >
                        {brv}
                      </div>
                    </th>
                  </tr>
                  <tr>
                    <th className="text-right pt-20">
                      <div
                        style={{
                          paddingRight: "30px",
                          marginTop: 20,
                          fontSize: "1.5em",
                        }}
                      >
                        {date}
                      </div>
                    </th>
                  </tr>
                </tbody>
              </table>
            </div>
            <>
              {data.map((d, i) => (
                <React.Fragment key={i}>
                  <table
                    className="table table-borderless d-flex justify-content-around t1"
                    style={{
                      marginTop: "180px",
                      marginBottom: "40px",
                      fontSize: "1.3em",
                    }}
                  >
                    <tbody className="">
                      <IndTBodyTR k="PRODUCT" v={product} />
                      <IndTBodyTR k="LOADING DPT." v={loading_depot} />
                      <IndTBodyTR k="PRES. OF WATER" v={presence_of_water} />
                      <IndTBodyTR k="OWNER" v={d.owner?.toUpperCase()} />

                      <IndTBodyTR k="REMARKS" v={d.remarks?.toUpperCase()} />
                    </tbody>
                    <tbody>
                      <IndTBodyTR k="C.H-FORE" v={`${hFore} (CM)`} />

                      <IndTBodyTR k="C.H-AFTER" v={`${hAfter} (CM)`} />

                      <IndTBodyTR k="L/DENSITY@20" v={`${lDensity} (g/ml)`} />
                      <div style={{}}>
                        <IndTBodyTR k="L/TEMP." v={`${lTemp} °C`} />
                      </div>
                      <IndTBodyTR
                        k="VOLUME@20"
                        v={`${volAt20?.toLocaleString("en")} (Lts)`}
                      />
                    </tbody>
                    <tbody style={{ display: "block" }}>
                    <IndTBodyTR k="DRIVER" v={driver.toUpperCase()} />

                      <div style={{ display: "block" }}>
                        <IndTBodyTR
                          k="TOTAL VOL OBS"
                          v={volObs?.toLocaleString("en")}
                        />
                      </div>
                      <div style={{ visibility: "hidden" }}>
                        <IndTBodyTR k="VCF" v={vcf} />
                      </div>
                      {/* <IndTBodyTR k="SUPERVISOR" v={d.supervisor?.toUpperCase()} /> */}
                      {/* <IndTBodyTR k="SEALER" v={d.sealer?.toUpperCase()} /> */}
                      <IndTBodyTR k="" v={""} />
                    </tbody>
                  </table>
                  <table
                    className="table table-borderless justify-content-round t2"
                    style={{ marginBottom: "100px", fontSize: "1.3em" }}
                  >
                    <tbody className="">
                      <tr>
                        <th className="text-center pb-0">RELEASED</th>
                        <th className="text-center pb-0">DIP/ULLAGE(CM)</th>
                        <th className="text-center pb-0">O.H (CM)</th>
                        <th className="text-center pb-0">TOP SEAL#</th>
                        <th className="text-center pb-0">DOWN SEAL #</th>
                      </tr>
                      <Rows
                        p={product}
                        r={RELEASED}
                        du={DIP_ULLAGE}
                        oh={oH}
                        ts={TOP_SEAL}
                        ds={DOWN_SEAL}
                      />
                    </tbody>
                  </table>
                </React.Fragment>
              ))}
            </>
            <div className="destination-box" style={{ marginBottom: "80px" }}>
              <div colSpan="5" style={{ display: "hidden" }}>
                {" "}
                {data.length > 0 ? "" : null}{" "}
                <strong>
                  {destination?.toUpperCase()} ( {owner} )
                </strong>
              </div>
            </div>
          </div>

          {data.length > 0 ? (
            <table
              className="table table-borderless"
              style={{ fontSize: "1.3em" }}
            >
              <tbody className="text-center">
                <tr>
                  <th>ISSUER</th>
                  <th>CUSTOMS</th>
                  <th className="text-right">BOST</th>
                  <th className="text-right">CARRIER</th>
                </tr>
                <tr>
                  <td>
                    <b>{issuer?.toUpperCase()}</b>
                  </td>
                  <td>
                    <b>{issuer?.toUpperCase()}</b>
                  </td>
                  <td>
                    <b>{issuer?.toUpperCase()}</b>
                  </td>
                  <td>
                    <b>{issuer?.toUpperCase()}</b>
                  </td>
                </tr>
              </tbody>
            </table>
          ) : null}
        </div>
      </div>
    );
	}
}
//DIP/ULLAGE(CM)	O.H (CM)	TOP SEAL#	DOWN SEAL #
function Rows({ p, r, du, oh, ts, ds }) {
	//console.table(ts)
	const data = [1, 2, 3, 4, 5, 6, 7];
	return (
		<>
			{data.map((d, i) => (
			
				<tr className="text-center" key={i}>
					<td className="">{(r[0][`released_${[i + 1]}`] || 0)}</td>
					<td className="">{du[0][`dip_ullage_${[i + 1]}`] || 0}</td>
					<td className="">{oh[0][`oh_${[i + 1]}`] || 0}</td>
					<td className="">{ts[0][`top_seal_${[i + 1]}`] || 'N/A'}</td>
					<td className="">{ds[0][`down_seal_${[i + 1]}`] || 'N/A'}</td>
				</tr>
			))}
		</>
	);
}
function IndTBodyTR({ k, v }) {
	return (
		<>
			<tr>
				<td className="key-light">{k}</td>
				<td>
					<strong>{v}</strong>
				</td>
			</tr>
		</>
	);
}
