import React, { Component, useState } from 'react'
import Error from "../Error/";
import { Create, Read, DeleteOne } from "../../controllers/"
class BDC_OMC extends Component {
	constructor() {
		super()
		this.formRef = React.createRef()
		this.state = {
			action: '',
			brv: '',
			err: [],
			data: [],
			bdc: '',
			transporters: '',
		}
	}
	componentWillReceiveProps = () => {

	}
	componentDidMount = async () => {

		const { action } = this.props;

		let data = [];

		data = await Read(`api/${action.toLowerCase()}`)
		if (data) {

			let c = data.map((d) => d.name)
			//  console.log(c);
			this.setState({ data: c })
		} else {
			this.setState({ err: ['Something went wrong'] })
		}
	}
	refreshData = (data, type) => {
		this.setState({ data })
		return this.props.refreshData(data, type)
	}
	handleInput = (m, n) => {
		this.setState({ [m]: n });
	}
	UPDATE_TRANSPORTER = async (e) => {
		const { data, transporters } = this.state;
		e.preventDefault()
		if (transporters === '') {

			this.setState({ err: ["Transporter name is required"] })
		} else {
			//send request
			const add = await Create('api/transporters/add', { name: transporters.toUpperCase() });
			if (add) {
				data.unshift(transporters.toUpperCase());
				this.setState({ transporters: "", data: [...data] })

			} else {
				this.setState({ err: ["Could not add Transporter, try again"], transporters: '' })
			}
		}

	}

	UPDATE_BRV = async (e) => {
		e.preventDefault();
		const { data, brv } = this.state
		if (brv === '') {
			this.setState({ err: ['BRV name is required'] });
		} else {
			//send request
			const add = await Create('api/brv/add', { name: brv.toUpperCase() });
			if (add) {
				data.unshift(brv.toUpperCase());
				this.setState({ data: [...data], brv: "" });
				this.refreshData(data,'transporter')
			} else {
				this.setState({ err: ['Could not add BRV, try again'], brv: '' });
			}
		}
	}
	UPDATE_BDC = async(e) => {
		e.preventDefault();
		const { data, bdc } = this.state
		if (bdc === '') {
			this.setState({ err: ['BDC name is required'] });
		} else {
			//send request
			const add = await Create('api/bdc/add', { name: bdc.toUpperCase() });
			if (add) {
				data.unshift(bdc.toUpperCase());
				console.log(data)
				this.setState({ data: [...data], bdc: "" });
				this.refreshData(data,'bdc')
			} else {
				this.setState({ err: ['Could not add BRV, try again'], bdc: '' });
			}
		}
	}
	render() {
		const { err, data } = this.state;
		// console.log(data);

		const { action, unmount } = this.props
		return (
			<div className="hjOpfT2 card">
				{err.length > 0 ? (
					<Error
						msg={err}
						close={() => {
							this.setState({ err: [] });
						}}
					/>
				) : null}
				<button
					onClick={() => {
						return unmount();
					}}
					className="close3"
				>
					&times;
				</button>

				<div className="prompt">
					<form ref={this.formRef} className="confirm">
						<div className="fm-main-field">
							{action === 'TRANSPORTERS' ? (
								<div className="fm-part-group ">
									<Input
										type="text"
										handler={this.handleInput}
										lbl="TRANSPORTERS"
										name="transporters"
										def={this.state.transporters}
									/>
									<button type="submit" className="submitBtn" onClick={this.UPDATE_TRANSPORTER}>
										ADD
									</button>
								</div>
							) : null}

							{action === 'BRV' ? (
								<div className="fm-part-group ">
									<Input
										type="text"
										handler={this.handleInput}
										lbl="BRV"
										name="brv"
										def={this.state.brv}
									/>
									<button type="submit" className="submitBtn" onClick={this.UPDATE_BRV}>
										ADD
									</button>
								</div>
							) : null}
							{action === 'BDC' ? (
								<div className="fm-part-group ">
									<Input
										type="text"
										handler={this.handleInput}
										lbl="BDC"
										name="bdc"
										def={this.state.bdc}
									/>
									<button type="submit" className="submitBtn" onClick={this.UPDATE_BDC}>
										ADD
									</button>
								</div>
							) : null}
						</div>

						<ListData data={data} refreshData={this.refreshData} listType={action} />
					</form>
				</div>
			</div>
		);
	}
}

export default BDC_OMC;
function ListData({ data, refreshData, listType }) {
	const [sleep, setSleep] = useState(false)
	const t = listType.toLowerCase();
	async function deleteBRV(e) {

		setSleep(true)

		let del = await DeleteOne(`api/${t}/remove/${e}`);
		if (del) {
			const rem = data.filter((d) => d !== e);
			setTimeout(setSleep(false), 700);
			return refreshData(rem, t);

		}
	}

	return (
		<>
			<div className="erTf">
				{sleep ? (
					<div className="">
						<div className="overlay dark position-absolute">
							<i className="fas fa-spinner fa-spin" style={{ fontSize: '2em' }}></i>
						</div>
					</div>
				) : null}

				<table className="bost-tbl">
					<tbody>
						{data.map((d, i) => (
							<tr key={i}>
								<td>{d}</td>
								<td>
									<button
										onClick={deleteBRV.bind(this, d)}
										value={d}
										type="button"
										style={{ width: '35px', float: 'right', marginRight: 10 }}
										className="badge badge-danger d-flex"
										disabled={sleep}
									>
										<span>
											<i className="fas fa-trash"></i>
										</span>
									</button>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</>
	);
}
function Input({ name, lbl, type, handler, def }) {

	function inputHandler(e) {

		return handler(e.target.name, e.target.value);
	}
	return (
		<div className="fieldEl">
			<div>
				<label>{lbl}</label>
			</div>
			<input type={type} name={name} value={def !== "" ? def : ""} onChange={inputHandler} />
		</div>
	);
}