import React, { Component,useEffect,useState } from 'react';
import MenuList from './menu-list';
import { Link, } from "react-router-dom";
import { authContext } from "../../hooks/use-auth";
import { Read } from '../../controllers/'
import { useSelector } from 'react-redux'
//=======================================================
//import {Grid} from '@material-ui/core'

//=============================================================
class Header extends Component {
	static contextType = authContext;
	constructor(props) {
		super(props);
		this.state = {
			menuToggle: false,
			depot: '',
			styles: {
				position: 'absolute',
				right: 20,
				width: 50, 
				top: 0,
				marginTop: 5,
			}
		};
	}
	componentDidMount = async () => {
		const d = await Read('api/user/depot');
		if (d) {
			this.setState({ depot: d });
		}
	}
	toggleMenu = () => {
		const { menuToggle } = this.state
		let toggle = menuToggle === false ? true : false
		this.setState({ menuToggle: toggle })

	}

	render() {
		const { menuToggle, depot } = this.state;
		const { showMenu, showLogout,notifications } = this.props;
		const auth = this.context?.user
		return (
			<>
				{<div className="main-header-wrapper">
					<header className="dsh_header">
						<div className="holderForNav">
							<nav className="navForDsh flx">
								<ul className="navUlDashForLogo flx">
									<li>
										<>
											{menuToggle === true ? <MenuList toggle={this.toggleMenu} /> : null}
										</>
									</li>
									<li>
										<Link to="/" className="a">
											<div className="elLogo">
												<img
													src={`${process.env.PUBLIC_URL}/bostLogo.png`}
													height="45px"
													alt="BOST"
												/>
												<div className="depot-name-container">
													<div className="depot-type">
														<span></span>
													</div>
												</div>
											</div>
										</Link>
									</li>
									<li className='nav-item' style={this.state.styles}>
										{auth && auth.level !== 'user' ?
											<>
												<a className="nav-link" href="#" style={{ color: 'rgba(0,0,0,.5)' }}>
													<i className="far fa-bell fa-lg"></i>
													{notifications.count> 0 &&  auth.level ==='approvals' ? <span style={{ minWidth: 15, height: 15, fontWeight: 500 }} className="badge badge-danger navbar-badge badge-lg">{notifications.count}</span> : null}
												</a>

											</> :
											null}
									</li>
									<li style={{ marginRight: 50 }}>
										{auth ?
											<>{

												<button className='' title='Sign out' style={{ float: 'right', border: 0, backgroundColor: 'transparent' }} onClick={this.handleSignOut} >

													<i className='fa fa-power-off fa-lg' style={{ color: 'rgba(0,0,0,.5)' }}></i>
												</button>

											}
											</>
											: null

										}
									</li>

								</ul>
							</nav>
						</div>
					</header>
				</div>
				}
			</>
		);

	}

	handleSignOut = (e) => {
		e.preventDefault()
		const { signout } = this.context;

		signout()
	}


}


export default Header;