import { useState, useEffect } from 'react'
import Layout from './Layout';
import { Outlet } from 'react-router-dom';
import Notification from '../../Notifications'
import {notificationUnreadDispatch} from '../../redux/actions/notifications'
import { connect } from 'react-redux'
import { useAuth } from '../../hooks/use-auth';
import { apiURL } from '../../urlConfig';

//====================================================================================
const io = require('socket.io-client');
const socket = io.connect(apiURL,
    {
        forceNew: true,
        reconnectionAttemps: 15,
        reconnectionDelay: 3000
    });
let audio = new Audio(`/sounds/alert1.wav`)

//---------------------------------------------------------------------------------------
function DashboardApp({ addNotification, theNotificationData}) {
    const { user } = useAuth();
    const [notificationCount, setCount] = useState(0);
    const [notificationData, setNotif] = useState([]);
    const [approvalNotif, setApprovalNotif] = useState([])
    //const [actionType, setAactionType] = useState('created')
    //const [isOldNotification, setIisOldNotification] = useState(false)
    //const [canPlaySound, setCcanPlaySound] = useState(false)
    //const [] = useState()

    useEffect(() => {
        let canPlaySound = false;
        socket.emit('get-notifications');
        socket.on('notifications', data => {
            theNotificationData(data)
            if (user.level && user.level === 'approvals') {
                setCount(data.created?.length)
                setNotif(data?.created)
                if (data.created?.length > 0) {
                    canPlaySound = localStorageWorker(data?.created)
                    saveOldNotifsLocal(data?.created)
                    canPlaySound && audio.play();
                }
            } else {
                setApprovalNotif(data?.approved)
                setCount(data.created?.length)
                if (data.approved?.length > 0) {
                    canPlaySound = localStorageWorker(data?.approved)
                    //console.log(canPlaySound,': sound')
                    canPlaySound && audio.play();
                    saveOldNotifsLocal(data?.approved)
                }
            }
        })
        //on notify
        socket.on('notify', payload => {
            // show only if current user role is approval person
            if (user.level === 'approvals') {
                setCount(payload.length);
                setNotif(payload);
                canPlaySound = localStorageWorker(payload)
                canPlaySound && audio.play();
                saveOldNotifsLocal(payload)
            }
        });
        //on approve brv -- shows for everyone but -- approval
        socket.on('brv-approved', payload => {
            //console.log(payload, 'brv approved')
            if (user.level!== 'approvals') {
                setApprovalNotif(payload);
                canPlaySound = localStorageWorker(payload)
                canPlaySound && audio.play();
                saveOldNotifsLocal(payload)
            }
        });
        //------------cleanup function
      
    }, []);
    const localStorageWorker = (currentNotifs) => {
        //to prevent sound from playing over and over for older notifications on reload
        let d = currentNotifs?.map((g) => g.brv_num)
        let hasNew = false;
        const data = JSON.parse(localStorage.getItem('_alertNotifs'))
        if (Array.isArray(data)) {
            for (let i = 0; i < d?.length; i++) {
                //check if brv # exists in old 
                if (!data?.includes(d[i])) {
                    hasNew = true;
                    break
                }
            }
            return hasNew
        } else {
            //	console.log('no localstorage data');
            return true
        }


    }
    const saveOldNotifsLocal = (data) => {
        const stateOfNotifs = data?.map((n) => n.brv_num);
        let savedNotifs = JSON.parse(localStorage.getItem('_alertNotifs'));
        if (Array.isArray(savedNotifs)) {
            for (let i = 0; i < stateOfNotifs?.length; i++) {
                if (!savedNotifs.includes(stateOfNotifs[i])) {
                    savedNotifs.push(stateOfNotifs[i]);
                }
            }
            localStorage.setItem('_alertNotifs', JSON.stringify(savedNotifs))
        } else {
            localStorage.setItem('_alertNotifs', JSON.stringify(stateOfNotifs))
        }
    }

    return (
        <>
            <Layout>
                {/* notifications addition push */}
                <>
                {
                        user.level === 'approvals' && notificationData.map((m, i) => (
                            <Notification key={i} info={m.brv_num} type={'created'} />
                        ))
                }
                {
                        user.level !== 'approvals' && approvalNotif.map((a, i) => (
                            <Notification key={i} info={a.brv_num} type={'approved'} />
                        ))
                }
                </>
              
                <Outlet />
            </Layout>

        </>
    )
}
const mapStateToProps = (state) => {
    return { notifications: {} }
}
const mapDispatchToProps = (dispatch) => {
    return {
        theNotificationData: (list) => { dispatch(notificationUnreadDispatch(list))}
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(DashboardApp)