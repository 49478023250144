import axios from "../../config/axios";
import API from '../API';
import {logOut} from "../../Auth"
import redirect from "../../redirects";
const Read = async (ENDPOINT) => {
  try {
    const read = await axios.get(`${API()}/${ENDPOINT}`);
    if (read) {
   //  console.log('intercept+++')
      return read.data;
    }
    return false;
  } catch (error) {
   
     if(error.response?.status ===401){
      //logout
       redirect()
       // console.log('401 with error',error.response.data,error)
    }
    return false;
  }
};
const ReadMany = async (ENDPOINT,keys) => {
    try {
      const read = await axios.get(`${API()}/${ENDPOINT}`, keys);
      if (read) {
        return read;
      }
      return false;
    } catch (error) {
   
      return false;
    }
  };
export {Read,ReadMany}