
export const FormatDate=(n)=>{
	let date= new Date(n);
	let d; let m; let y;
	let h =makeStruct(date.getHours());
	let min = makeStruct(date.getMinutes());
	//let sec = date.getSeconds();
	const time = `${h}:${min}`;
	
	d =makeStruct(date.getDate());
	m = makeStruct(date.getMonth()+1);
	y = date.getFullYear();
	const fd = `${d}-${m}-${y}`
	
	return {date:fd, time}|| null	
}

function makeStruct(x){
	if(x.toString().length === 1){
		return `0${x}`
	}
	
	return x
}