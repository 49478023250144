import React from 'react';
import { useRoutes } from 'react-router-dom'
import { AdminRoleGuard, EntryRoleGuard, FallbackGuard } from './Components/Guard';
//import Create from './Admin/Users/create';
//import PrintWaybill from './Components/PrintWaybill';
//import Dashboard from './Admin/index';
//import RecallFormEdit from './Components/form/recall-form';
//import Form from './Components/form';
//import Users from './Admin/Users/Users';
import DashboardApp from './Admin/Dashboard/'
//import DashboardLanding from './Admin/Dashboard/DashboardLanding';
// import Landing from './Admin/Dashboard/Landing';
// import Approvals from './Approvals/'
// //import Report from './Admin/Report/';
// import MuiReport from './Admin/Report/MuiReport';
// import BackupData from './Admin/Backup';
 import {allowedRoutesForNonEntryRole,allowedRoutesForEntryRole} from './Views/allowedRoutesForRole';
import { useAuth } from './hooks/use-auth';


//---------------------------------------------------------------------------------------------
export default function AppRouter() {
    
    const { user } = useAuth();
const nonUserRoutes=()=>{

   return user&&user.designation===1&&user.level!=='user'&&[...allowedRoutesForNonEntryRole[user && user.level]]
}
const entryUserRoutes=()=>{
       return user&&user.level ==='user'&&[...allowedRoutesForNonEntryRole[user && user.level]]

}
  //console.log(entryUserRoutes());

    return useRoutes([
        { path: '/', element: <FallbackGuard /> },
        {
            path: '/admin', element: <AdminRoleGuard><DashboardApp /></AdminRoleGuard>,
            children: user.level!=='user' && nonUserRoutes() ||[]
        },
        {
            path: '/app', element: <EntryRoleGuard />,
            children: user.level === 'user' && entryUserRoutes() || []
        },
        { path: '*', element: <FallbackGuard /> }
    ])
}