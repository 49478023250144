import React from 'react'
import { Grid, Box, styled, MenuItem, Typography, Avatar, Divider } from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom'
import DashboardIcon from '@material-ui/icons/Dashboard'
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import FolderIcon from '@material-ui/icons/Folder';
import ReceiptIcon from '@material-ui/icons/Receipt';
import { useAuth } from '../../hooks/use-auth';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

//--------------------------------------------------
const Wrapper = styled(Box)({
    height: '100vh',
    width: 240,
    backgroundColor: '#0e213a',

})
const MenuTypogrphy = styled(Typography)({
    fontWeight: 600,
    fontSize: '.95rem',
    marginLeft: 10,
    textTransform: 'capitalize',
    fontFamily: 'Montserrat',
    color: '#fff',
    margin: 8

});


export default function Sidebar() {
    const { user } = useAuth()
    return (
        <>
            <Wrapper>
                <Grid container spacing={2}>
                    <Grid item xs={12} style={{ height: 10, padding: 10 }}></Grid>
                    <Grid item xs={12} style={{ height: 70, padding: 10 }}>
                        <Box sx={{ paddingLeft: 10, pb: 2, display: 'flex', borderBottom:'1px solid #5c5c5c'}}>
                            <Avatar sx={{ marginTop: 10 }} />
                            <Box sx={{ ml: 2 }}>
                                <Typography variant='body2' style={{ color: '#fff' }}>{user.fullName}&nbsp;<CheckCircleIcon style={{fontSize:'.95rem', color:'#17b317'}}/></Typography>
                                <Typography variant='caption' style={{ color: '#fff' }}>{user.level}</Typography>
                            </Box>
                        </Box>
                       
                    </Grid>
                    <Grid item xs={12}>

                        {user.level === 'admin' &&
                            <MenuItem component={RouterLink} to='/admin/'>
                                <DashboardIcon style={{ color: 'grey' }} />
                                <MenuTypogrphy variant='body2'> Dashboard</MenuTypogrphy>
                            </MenuItem>}

                        {user.level === 'admin' &&
                            <MenuItem component={RouterLink} to='/admin/users'>
                                <PeopleAltIcon style={{ color: 'grey' }} />
                                <MenuTypogrphy>Users</MenuTypogrphy>
                            </MenuItem>
                        }
                        {user.level === 'admin' &&
                            <MenuItem component={RouterLink} to='/admin/report'>
                                <FolderIcon style={{ color: 'grey' }} />
                                <MenuTypogrphy>Reports</MenuTypogrphy>
                            </MenuItem>}

                        {user.level === 'invoicing' &&
                            <MenuItem component={RouterLink} to='/admin/report'>
                                <ReceiptIcon style={{ color: 'grey' }} />
                                <MenuTypogrphy>Invoicing</MenuTypogrphy>
                            </MenuItem>
                        }
                        {user.level === 'approvals' &&
                            <MenuItem component={RouterLink} to='/admin/approvals'>
                                <FolderIcon style={{ color: 'grey' }} />
                                <MenuTypogrphy>Approvals</MenuTypogrphy>
                            </MenuItem>}

                        {user.level === 'viewer' &&
                            <MenuItem component={RouterLink} to='/admin/report'>
                                <FolderIcon style={{ color: 'grey' }} />
                                <MenuTypogrphy>Viewer</MenuTypogrphy>
                            </MenuItem>
                        }
                    </Grid>

                </Grid>
            </Wrapper>
        </>
    )
}
