import axios from "axios";
import API from '../API';
import {logOut} from "../../Auth"
export const DeleteOne = async (ENDPOINT) => {
  try {
    const deleteOne = await axios.delete(`${API()}/${ENDPOINT}`);
    if (deleteOne) {
     
      return deleteOne.data;
    }
    return false;
  } catch (error) {
    if(error.response?.status ===401){
      //logout
        logOut();
    }
    return false;
  }
};