import { Read } from '../../controllers/Read/';
import React, { Component, useRef } from 'react';
import { Link } from 'react-router-dom';
import { authContext } from '../../hooks/use-auth';

//-----------------------------------------------------------
class DashboardLanding extends Component {
    static contextType = authContext
    constructor() {
        super();
        this.state = {
            users: '',
            fanbill: '',
        };
    }
    handlePreloader = () => {
       // return this.props.process(false);
    };
    componentDidMount = async () => {

        const r = await Read('api/user/count');
        //console.log(r);
        if (r) {
            this.setState({ users: r.users, fanbill: r.fanbill });
        } else {
        }
        this.handlePreloader();
    };
    render() {
        const { users, fanbill } = this.state;
        const { user } = this.context
        return (
            <div className="">
                <div className="dashboard-preview">
                    {

                        user&&user.level === "admin" ?
                            <div className="UIbT1">
                                
                                <Link to="/admin/users">
                                    <h3>
                                       
                                        {users} Users
                                    </h3>
                                    <em className='badge badge-warning'>Total number of users</em>
                                </Link>
                            </div> : null}
                    {user&&user.level === "admin" || user&&user.level === "invoicing" || user&&user.level === "viewer" ?
                        <div className="UIbT2">
                            <Link to="/admin/report">
                                <h3>
                                    <span>{fanbill} Records</span>
                                </h3>
                                <em className='badge badge-info'>Total waybill records made</em>
                            </Link>
                        </div> : null}
                    {user&&user.level === "approvals" ?
                        <div className="UIbT2">
                            <Link to="/admin/approvals">
                                <h1>
                                    Approvals
                                </h1>
                                <em>Approve or Disapprove a waybill entry</em>
                            </Link>
                        </div> : null}
                </div>
            </div>
        );
    }
}
export default DashboardLanding