import React, { Component } from "react";
import { Read, Create } from "../../controllers/";
//import { Input, Select } from '../../Components/Elements/form-input/input';
import { BRV } from "../../Components/Elements/Constants";
import Error from "../../Components/Error/";
import { FormatDate } from "../../Helpers/Dates";
import { CSVLink } from "react-csv";
import { DeleteOne } from "../../controllers/Remove/DeleteOne";
import { userDetail } from "../../Auth/";
import { Link } from "react-router-dom";
import ConfirmWindow from "../../Components/prompto";
import FilterModal from "../filter";
import MuiReport from './MuiReport'

//------------------------------------------------------------------
const usr = userDetail();
export default class Report extends Component {
  constructor() {
    super();
    this.state = {
      records: [],
      dateFrom: null,
      dateTo: null,
      status: "",
      product_type: "",
      bdc: "",
      GRAND_TOTAL: 0,
      VOLAT20_TOTAL: 0,
      VOL_OBS: 0,
      fileName: "",
      csvData: [],
      stamp: 0,
      fanBillNum: "",
      checked: "",
      currUser: "",
      deleteDialog: false,
      paginate: null,
      brvs: [],
      volume_obs: "",
      owner: "",
      recieving_depot: "",
      transporter: "",
      transporterList: [],
      OWNERS: [],
    };
  }
  //PAGINATION

  componentDidMount = async () => {
    //console.log(this.props)
    const epoch = Math.round(Date.now() / 1000);
    this.setState({ stamp: epoch, currUser: usr.uid });
    const _OWNERS = await Read("api/bdc/");
    if (_OWNERS) {
      let v = _OWNERS.map((b) => b.name);
      this.setState({ OWNERS: v });
    }
    const transporters = await Read("api/transporters");
    if (transporters) {
      //console.log(transporters)
      let v = transporters.map((b) => b.name);
      this.setState({ transporterList: v });
    }

    let getRecords = await Read("api/fan-bill/approved");

    if (getRecords) {
      //setting
      //console.log(getRecords)
      let records = getRecords;
      let tmp1 = 0;
      let tmp2 = 0;
      let v2 = getRecords.map((t) => t.volumeAt20);
      let tt = getRecords.map((t) => t.total);

      for (let i = 0; i < v2.length; i++) {
        tmp1 += parseFloat(v2[i]);
      }
      for (let i = 0; i < tt.length; i++) {
        tmp2 += parseFloat(tt[i]);
      }
      const brvs = await BRV();

      this.setState({ records, GRAND_TOTAL: tmp2, VOLAT20_TOTAL: tmp1, brvs });
      //console.log(records);

      // GRAND_TOTAL: tmp2, VOLAT20_TOTAL: tmp1
      /*	this.setState({ records: getRecords, GRAND_TOTAL: tmp2, VOLAT20_TOTAL: tmp1 });
       */
      this.preloaderProcess(false);
    } else {
      this.setState({ err: true });
    }

    //prepare data for excel export
    this.getCsvData();
  };
  calcTotals = () => {
    const { records } = this.state;
    let v2 = records.map((t) => t.volumeAt20);
    let tt = records.map((t) => t.total);
    let tmp1 = 0;
    let tmp2 = 0;
    for (let i = 0; i < v2.length; i++) {
      tmp1 += parseFloat(v2[i]);
    }
    for (let i = 0; i < tt.length; i++) {
      tmp2 += parseFloat(tt[i]);
    }
    this.setState({ GRAND_TOTAL: tmp2, VOLAT20_TOTAL: tmp1 });
  };
  preloaderProcess = (n) => {
    let { process } = this.props;
    return
    //return process(n);
  };
  handler = (m, n) => {
    //console.log(m,n)
    this.setState({ [m]: n });
  };

  onSubmitFilter = async (e) => {
    e.preventDefault();
    this.setState({
      dateFrom: null,
      dateTo: null,
      brv_num: "",
      status: "",
      product_type: "",
    });
    this.preloaderProcess(true);
    const epoch = Math.round(Date.now() / 1000);
    this.setState({ stamp: epoch });
    const {
      dateFrom,
      dateTo,
      brv_num,
      product_type,
      transporter,
      recieving_depot,
      volume_obs,
      fanBillNum,
      owner,
    } = this.state;
    /*  console.log(
		 		dateFrom,
		 		dateTo,
		 		brv_num,
		 		status,
		 		product_type,
		 		transporter,
		 		recieving_depot,
		 		volume_obs,
		 		fanBillNum,
		 		owner
		 	);*/

    const post = await Create("api/fan-bill/filter", {
      dateFrom,
      dateTo,
      brv_num,
      product_type,
      status: "approved",
      transporter,
      recieving_depot,
      volume_obs,
      owner,
    });
    //  console.log(post," => res")
    if (post) {
      let v2 = post.map((t) => t.volumeAt20);
      let tt = post.map((t) => t.total);
      let tmp1 = 0;
      let tmp2 = 0;
      for (let i = 0; i < v2.length; i++) {
        tmp1 += parseFloat(v2[i]);
      }
      for (let i = 0; i < tt.length; i++) {
        tmp2 += parseFloat(tt[i]);
      }

      this.setState({ records: post, GRAND_TOTAL: tmp2, VOLAT20_TOTAL: tmp1 });
      this.preloaderProcess(false);
    } else {
      this.setState({ err: true });
    }
  };

  getCsvData = () => {
    const csvData = [
      [
        "DATE",
        "TRANSACTION #",
        "BRV #",
        "OWNER",
        "TRANSPORTER",
        "PRODUCT",
        "VOLUME@20 (ℓ)",
        "VOLUME OBS. (ℓ)",
        "RECEIVING DEPOT",
        "DRIVER",
        "METER/ARM #",
        "LOADING DEPOT",

        "DENSITY@20 (g/ℓ)",
        // "VCF",
        "SUPERVISOR",
        "APPROVED BY",
        "SEALER",
        "DIPPER",
        "TEMP(℃)",
        "REMARKS",
      ],
    ];
    const getRecords = this.state.records;
    const { GRAND_TOTAL, VOLAT20_TOTAL } = this.state;
    for (let i = 0; i < getRecords.length; i += 1) {
      const arr = [
        `${FormatDate(getRecords[i].record_date).date}`,
        `${getRecords[i].coll_order}`,
        `${getRecords[i].brv_num}`,
        `${getRecords[i].owner}`,
        `${getRecords[i].transporter}`,
        `${getRecords[i].product_type}`,
        `${getRecords[i].volumeAt20}`,
        `${getRecords[i].total}`,
        `${getRecords[i].recieving_depot}`,
        `${getRecords[i].driver}`,
        `${getRecords[i].meter_arm_num}`,
        `${getRecords[i].loading_depot}`,

        `${getRecords[i].litre_density_at_20}`,
        // `${getRecords[i].vcf}`,
        `${getRecords[i].supervisor}`,
        `${getRecords[i].approved_by}`,
        `${getRecords[i].sealer}`,
        `${getRecords[i].dipper}`,
        `${getRecords[i].litre_temp}`,
        `${getRecords[i].remarks}`,
      ];
      csvData.push(arr);
    }

    csvData.push([
      ``,
      ``,
      ``,
      ``,
      ``,
      ``,
     
      `${VOLAT20_TOTAL}`,
        `${GRAND_TOTAL}`,
      ``,
      ``,
      ``,
      ``,
     ``,
      ``,
      
      ``,
      ``,
      ``,
      ``,
      ``,
    ]);

    return csvData;
  };
  invoicingStatus = (params) => {
    const s = params === "CLEARED" ? "approve-btn" : "btn-warning";
    return s;
  };
  handleDialog = async (e) => {
    if (e) {
      await this.del();
      await this.calcTotals();
    }
    this.setState({ deleteDialog: false });
  };
  showDialog = (e) => {
    this.setState({ deleteDialog: true, checked: e.target.value });
  };

  render() {
    const {
      OWNERS,
      deleteDialog,
      brvs,
      stamp,
      err,
      GRAND_TOTAL,
      VOLAT20_TOTAL,
      transporterList,
    } = this.state;

    return (
      <div style={{ display: "block" }}>
        <button
          className="btn btn-info"
          data-toggle="modal"
          data-target="#modal-sm"
        >
          Filter
          <span aria-hidden="true">
            <i className="fas fa-filter"></i>
          </span>
        </button>
        {deleteDialog ? (
          <ConfirmWindow
            msg="Confirm to delete selected row"
            action={this.handleDialog}
          />
        ) : null}

        {err ? (
          <Error
            close={() => {
              this.setState({ err: false });
            }}
            msg={["Something went wrong, Please Try again"]}
          />
        ) : null}
        {}
        <FilterModal
          role={this.props.role}
          func={this.handler}
          brvs={brvs}
          onSubmit={this.onSubmitFilter}
          transporterList={transporterList}
          owners={OWNERS}
        />

        <span style={{ color: "#888" }}>
          Search results {`${this.state.records.length}`}
        </span>
        {this.state.records.length > 0 ? (
          <ExportReactCSV
            csvData={this.getCsvData()}
            fileName={`BOST-APD-${stamp}.csv`}
          />
        ) : null}
        {
          <div className="tbl-wrap2">
            <table className="bost-tbl">
              <tbody>
                <tr className="">
                  {this.props.role !== "viewer" ? (
                    <th className="sticky">ACTION</th>
                  ) : null}
                  <th className="sticky">APPROVAL STATS</th>
                  <th className="sticky">DATE</th>
                  <th className="sticky">OWNER</th>
                  <th className="sticky">TRANSPORTER </th>
                  <th className="sticky">DRIVER</th>
                  <th className="sticky">BRV #</th>
                  <th className="sticky">PRODUCT TYPE</th>
                  <th className="sticky">METER/ARM #</th>
                  <th className="sticky">LOADING DEPOT</th>
                  <th className="sticky">RECEIVING DEPOT</th>
                  { <th className="sticky">VOLUME OBS(ℓ)</th> }
                  { <th className="sticky">TEMP(℃)</th> }
                  <th className="sticky">DENSITY @20(g/ml)</th>
                  {/* <th className="sticky">VCF</th> */}
                  <th className="sticky">VOLUME @20(ℓ)</th>
                  <th className="sticky">SUPERVISOR</th>
                  <th className="sticky">APPROVED BY</th>
                  <th className="sticky">SEALER</th>
                  <th className="sticky">DIPPER</th>
                  <th className="sticky">REMARKS</th>
                </tr>

                {this.state.records.map((r, i) => (
                  <tr key={i}>
                    {this.props.role?.toLowerCase() !== "viewer" ? (
                      <td>
                        {this.props.role?.toLowerCase() === "admin" ? (
                          <button
                            onClick={this.showDialog}
                            value={r.id}
                            className="xcYi3  disapprove-btn"
                          >
                            DELETE
                          </button>
                        ) : null}
                        {this.props.role !== "viewer" ? (
                          <Link to={`/admin/doc/${r.id}`}>
                            <button
                              onClick={this.onView}
                              value={i}
                              className="xcYi3 view-btn"
                            >
                              VIEW
                            </button>
                          </Link>
                        ) : null}
                      </td>
                    ) : null}
                    {this.props.role === "admin" ? (
                      <td>
                        <button className="xcYi3 approve-btn">
                          {r.status.toUpperCase()}
                        </button>
                      </td>
                    ) : null}
                    {this.props.role === "invoicing" ? (
                      <td>
                        <button
                          className={`xcYi3 ${this.invoicingStatus(
                            r.invoicingStatus
                          )}`}
                        >
                          {r.invoicingStatus ? r.invoicingStatus : "PENDING"}
                        </button>
                      </td>
                    ) : null}
                    {this.props.role?.toLowerCase() === "viewer" ? (
                      <td>
                        <button
                          className={`xcYi3 ${this.invoicingStatus(
                            r.invoicingStatus
                          )}`}
                        >
                          {r.invoicingStatus ? r.invoicingStatus : "PENDING"}
                        </button>
                      </td>
                    ) : null}
                    <td>{FormatDate(r.record_date).date}</td>
                    <td>{r.owner.toUpperCase()}</td>
                    <td>{r.transporter?.toUpperCase()}</td>
                    <td>{r.driver?.toUpperCase()}</td>
                    <td>{r.brv_num}</td>
                    <td>{r.product_type}</td>
                    <td>{r.meter_arm_num}</td>
                    <td>{r.loading_depot}</td>
                    <td>{r.recieving_depot}</td>
                    { <td>{r.total?.toLocaleString("en")}</td> }
                    { <td>{r.litre_temp}</td> }
                    <td>{r.litre_density_at_20}</td>
                    {/* <td>{r.vcf}</td> */}
                    <td>{r.volumeAt20?.toLocaleString("en")}</td>
                    <td>{r.supervisor?.toUpperCase()}</td>
                    <td>{r.approved_by?.toUpperCase()}</td>
                    <td>{r.sealer?.toUpperCase()}</td>
                    <td>{r.dipper?.toUpperCase()}</td>
                    <td>{r.remarks}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <table className="tbl-volume">
              <tbody style={{}}>
                <tr>
                  <td>
                    <strong>TOTAL VOLUME OBSERVED = </strong>
                  </td>
                  <td className="total_volObs">
                    <strong>{GRAND_TOTAL?.toLocaleString("en")}</strong>
                  </td>
                </tr>
              </tbody>
              <tbody>
                <tr>
                  <td>
                    <strong>TOTAL VOLUME@20 = </strong>
                  </td>
                  <td className="total_volObs">
                    <strong>{VOLAT20_TOTAL?.toLocaleString("en")}</strong>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        }
        <MuiReport/>
      </div>
    );
  }

  del = async () => {
    const { checked, currUser, records } = this.state;
    if (checked !== "" && currUser !== "") {
      const rem = await DeleteOne(`api/fan-bill/remove/${currUser}/${checked}`);
      if (rem) {
        const dl = records.filter((r) => {
          if (r.id !== Number(checked)) {
            return r;
          }
          return null;
        });
        //const index = records.findIndex()

        this.setState({ records: [...dl] });
      } else {
        this.setState({ err: true });
      }
    } else {
      this.setState({ err: true });
    }
  };
  handleCheck = (e) => {
    let { checked } = this.state;
    const val = checked === e.target.value ? "" : e.target.value;
    this.setState({ checked: val });
  };
}

export const ExportReactCSV = ({ csvData, fileName }) => {
  return (
    <div className="yuOp2">
      <button className="submitBtn to-excel-btn">
        <CSVLink data={csvData} filename={fileName}>
          <img
            src={`${process.env.PUBLIC_URL}/excel.png`}
            alt="export to excel"
          />
        </CSVLink>
      </button>
    </div>
  );
};
