import React from 'react';
import { ProductList } from '../../Helpers/Products';
const FilterModal = ({ func, onSubmit, brvs, role, transporterList, owners }) => {
	function onFilter(e) {
		e.preventDefault();
		return onSubmit(e);
	}
	const handler = (e) => {
		return func(e.target.name, e.target.value);
	};
	return (
		<section className="content">
			<div className="container-fluid">
				<div className={`modal fade`} id="modal-sm" style={{ display: 'none' }} aria-hidden="true">
					<div className="modal-dialog modal-sm">
						<div className="modal-content">
							<div className="modal-header">
								<h4 className="modal-title">Filter</h4>
								<button type="button" className="close" data-dismiss="modal" aria-label="Close">
									<span aria-hidden="true">×</span>
								</button>
							</div>
							<div className="modal-body">
								<form style={{ width: '100%' }}>
									<div className="">
										<div className="row">
											<div className="col-sm-6">
												<div className="form-group">
													<label htmlFor="">
														<small>Date from</small>
													</label>
													<input
														type="date"
														name="dateFrom"
														className="form-control form-control-sm"
														placeholder=""
														onChange={handler}
													/>
												</div>
											</div>
											<div className="col-sm-6">
												<div className="form-group">
													<label htmlFor="">
														<small>Date to</small>
													</label>
													<input
														type="date"
														name="dateTo"
														className="form-control form-control-sm"
														placeholder=""
														onChange={handler}
													/>
												</div>
											</div>
										</div>
										<div className="row">
											<div className="col-sm-6">
												<div className="form-group">
													<label htmlFor="">
														<small>Product Type</small>
													</label>
													<select
														className="custom-select form-control-sm"
														name="product_type"
														onChange={handler}
													>
														<option></option>
														{(ProductList || []).map((c, i) => (
															<option key={i} value={c}>
																{c}
															</option>
														))}
													</select>
												</div>
											</div>
											<div className="col-sm-6">
												<div className="form-group">
													<label htmlFor="">
														<small>BRV #</small>
													</label>
													<input
														className="custom-select form-control-sm"
														name="brv_num"
														onChange={handler}
														list='brv'
													/>
														<datalist id="brv">
															{(brvs || []).sort().map((c, i) => (
																<option key={i} value={c}/>
																
															))}
														</datalist>
												</div>
												</div>
											</div>
											<div className="row">
												<div className="col-md-6">
													<div className="form-group">
														<label htmlFor="">
															<small>Transporter</small>
														</label>
														<select
															className="custom-select form-control-sm"
															name="transporter"
															onChange={handler}
														>
															<option></option>
															{(transporterList || []).map((c, i) => (
																<option key={i} value={c}>
																	{c}
																</option>
															))}
														</select>
													</div>
												</div>
												<div className="col-md-6">
													<div className="form-group">
														<label htmlFor="">
															<small>Receiving Depot</small>
														</label>
														<select
															className="custom-select form-control-sm"
															name="recieving_depot"
															onChange={handler}
														>
															<option></option>
															{(['AKOSOMBO', 'BUIPE', 'KUMASI'] || []).map((c, i) => (
																<option key={i} value={c}>
																	{c}
																</option>
															))}
														</select>
													</div>
												</div>
											</div>
											<div className='row'>
												<div className="form-group">
													<label htmlFor="">
														<small>Waybill #</small>
													</label>
													<input
														type="text"
														name="fanBillNum"
														className="form-control form-control-sm"
														placeholder=""
														onChange={handler}
													/>
												</div>
												<div className='form-group'>
													<label htmlFor="">
														<small>Owner</small>
													</label>
													<input
														type="text"
														name="owner"
														className="form-control form-control-sm"
														placeholder=""
														onChange={handler}
														list='owner'
													/>
													<datalist id='owner' list='owner'>
														{
															owners?.map((o) => (
																<option key={o} value={o} />
															))
														}

													</datalist>
												</div>
											</div>

											<div className="form-group">
												<label htmlFor="">
													<small>Volume Obs.</small>
												</label>
												<input
													type="text"
													name="volume_obs"
													className="form-control form-control-sm"
													placeholder=""
													onChange={handler}
												/>
											</div>

											{role?.toLowerCase() === 'approvals' ? (
												<div className="form-group">
													<label htmlFor="">
														<small>Status</small>
													</label>
													<select
														className="custom-select form-control-sm"
														name="status"
														onChange={handler}
													>
														<option></option>
														{(['APPROVED', 'PENDING'] || []).map((c, i) => (
															<option key={i} value={c}>
																{c}
															</option>
														))}
													</select>
												</div>
											) : null}
										</div>

										<div className="card-footer">
											<button
												type="submit"
												onClick={onSubmit}
												className="btn btn-primary"
												data-dismiss="modal"
											>
												Submit
											</button>{' '}
											<button type="button" className="btn" data-dismiss="modal">
												Close
											</button>
										</div>
								</form>
							</div>
							</div>
							{/* <!-- /.modal-content --> */}
						</div>
						{/* <!-- /.modal-dialog --> */}
					</div>
				</div>
		</section>
			);
};
			export default FilterModal;
