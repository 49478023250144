import React from 'react'
import { Navigate } from 'react-router-dom'
import { useAuth } from '../../hooks/use-auth';
//----------------------------------------------------
export default function AdminRoleGuard({ children }) {

    const { user } = useAuth();
    //console.log(user, 'guard')
    return (
        <>
        {
            user&&user.designation===1&&user.level !=='user'&&<>
                {user && user.level === 'admin' && children}
                {user && user.level === 'invoicing' && children}
                {user && user.level === 'viewer' && children}
                {user && user.level === 'approvals' && children}
                {user && user.level === 'user' && <Navigate to='/app' replace/>}

            </>
        }
        {
            user&&user.designation!==1&&platformHandler()
        }
        </>
    )
}

const platformHandler=()=>{
    return process.env.NODE_ENV==='production'?(window.location.href='https://omc.bostmonitor.com'):(window.location.href='http://localhost:3001')
}