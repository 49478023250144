import React from 'react'
import { Outlet } from 'react-router-dom'
import { useAuth } from '../../hooks/use-auth'
//--------------------------------------------
export default function EntryRoleGuard({ children }) {
    const { user } = useAuth();
    return (
        <>
            {
                user && user.level === 'user' && children
            }
            <Outlet />
        </>
    )
}
