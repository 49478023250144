import React from 'react';
import NotificationCreate from './NotificationCreate'
const Notification = (props) => {
   
  //  console.log(props,' --> checking props')
    return (
        <>
            <NotificationCreate {...props} />
        </>
    )
}

export default Notification