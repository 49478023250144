import React, { Component } from 'react';
import { FormatDate } from '../Helpers/Dates';
import { Update, Read } from '../controllers/';
import Error from '../Components/Error';
import { Link ,useNavigate} from 'react-router-dom';
import { userDetail } from '../Auth';
import { apiURL } from '../urlConfig';
import { styled, Table, TableBody, TableCell, TablePagination,TableContainer, TableRow, TableHead, Typography } from '@material-ui/core'


//-----------------------------------------------------
const TableHeadCell = styled(TableCell)({
	fontSize: '1rem',
	fontWeight: 600
})
const io = require('socket.io-client')
const socket = io.connect(apiURL,
	{
		forceNew: true,
		reconnectionAttemps: 15,
		reconnectionDelay: 3000
	})
class Approvals extends Component {
	//table for user status
	constructor() {
		super();
		this.state = {
			preloader: false,
			records: [],
			err: [],
			hasViewed: false,
			rowsPerPage:10,
			page:0
		};
	}
	componentDidMount = async () => {
		this.handleLoader(false);
		await this.fetchPendingWaybill();
	};
	fetchPendingWaybill = async () => {
		const records = await Read(`api/fan-bill/pending`);

		if (records) {
			let tmp = 0;
			let tt = records.map(t => t.total);
			for (let i = 0; i < tt.length; i++) {
				tmp += parseFloat(tt[i]);

			}
			this.setState({ records, GRAND_TOTAL: tmp });
			//console.log(records);
		} else {
			this.setState({ err: ['Operation failed !'] });
		}
		return;
	};

	handleLoader = n => {
		const { process } = this.props;
		//return process(n);
	};

	onChangeStatus = async (action, doc, index) => {
		const { records } = this.state;
		//const action = e.target.value;
		//	const type = e.target.dataset.action

		let body = { status: action, approved_by: `${userDetail().name}` };
		//console.log(body,doc,index);

		const approve = await Update(`api/approvals/${doc}`, body);
		if (approve) {
			//erro
			//	let index = e.target.tabIndex;
			//send notification
			socket.emit('approve-brv')
			records.splice(index, 1);
			this.setState({ records: [...records] });
		} else {
			this.setState({ err: ['Operation failed'] });
		}
	};

	onView = () => { };
	onCloseErr = () => {
		this.setState({ err: [] });
	};
	//pagination funcs
	 handleChangePage = (event, newPage) => {
   
    this.setState({page:newPage})
  };

   handleChangeRowsPerPage = (event) => {
   this.setState({rowsPerPage:parseInt(event.target.value, 10),page:0})
  };
	render() {
		const { records, err, GRAND_TOTAL ,rowsPerPage,page} = this.state;

		return (
			<div>
				{<Error msg={err} close={this.onCloseErr} />}
				<div>
					<TableContainer>
						<Table>
							<TableHead>
								<TableRow>
									<TableHeadCell>ACTION</TableHeadCell>

									<TableHeadCell>DATE</TableHeadCell>
									<TableHeadCell>PRODUCT</TableHeadCell>
									<TableHeadCell>BRV #</TableHeadCell>
									<TableHeadCell>VOL OBS</TableHeadCell>

									<TableHeadCell>SUPERVISOR</TableHeadCell>

									<TableHeadCell>SEALER</TableHeadCell>
									<TableHeadCell>DIPPER</TableHeadCell>
									<TableHeadCell>RELEASING TANK</TableHeadCell>
									<TableHeadCell>REMARKS</TableHeadCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{(records || [])
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
									.map((r, i) => (
									<TableRow key={i}>
										<TableCell>
											<button
												onClick={this.onChangeStatus.bind(this, 'APPROVED', r.id, i)}
												tabIndex={i}
												value={r.id}
												data-action="approve"
												className="xcYi3 approve-btn"
												disabled={!r.viewed}
											>
												APPROVE
											</button>
											<button
												onClick={this.onChangeStatus.bind(this, 'DISAPPROVED', r.id, i)}
												tabIndex={i}
												data-action="disapprove"
												value={r.id}
												className="xcYi3 disapprove-btn"
												disabled={!r.viewed}
											>
												DISAPPROVE
											</button>

											
											<InvoiceRedirector rowId={r.id}/>
										</TableCell>
										{/* <TableCell> */}
										{/* 	<button className="xcYi3 gold">PENDING</button> */}
										{/* </TableCell> */}
										<TableCell>{FormatDate(r.record_date).date}</TableCell>
										<TableCell>{r.product_type?.toUpperCase()}</TableCell>
										<TableCell>{r.brv_num}</TableCell>
										<TableCell>{r.total}</TableCell>
										<TableCell>{r.supervisor?.toUpperCase()}</TableCell>

										<TableCell>{r.sealer?.toUpperCase()}</TableCell>
										<TableCell>{r.dipper?.toUpperCase()}</TableCell>
										<TableCell>{r.releasing_tank}</TableCell>
										<TableCell>{r.remarks}</TableCell>
									</TableRow>
								))}
							</TableBody>
							<TableBody style={{position:'fixed',bottom:0}}>
								<TableRow>
									{records.length > 0 && 
									<TableCell>
										<strong>TOTAL VOLUME OBSERVED = </strong> <strong>{GRAND_TOTAL?.toLocaleString('en')}</strong>
									</TableCell>
									}
								</TableRow>
							</TableBody>
						</Table>
					</TableContainer>

				   <TablePagination
                      rowsPerPageOptions={[10,25,50,100,250]}
                      component="div"
                      count={records.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={this.handleChangePage}
                      onRowsPerPageChange={this.handleChangeRowsPerPage}
                    />

					{records.length === 0 ? <label className="lbl-empty">No entries available !</label> : null}
				</div>
			</div>
		);
	}
}
export default Approvals;

function InvoiceRedirector({ rowId }) {
	const navigate = useNavigate();
	const onNavigate = () => {
		sessionStorage.setItem('__viewable_invoice', rowId)
		navigate(`/admin/doc/${rowId}`)
	}
	return (
		<>
			<button
				onClick={onNavigate}
				value={rowId}
				className="xcYi3 view-btn"
			>
				VIEW
			</button>

		</>
	)

}