import React from 'react';

const BackupData = (props) => {
  //  console.log(props)
    React.useEffect(()=>{
        preloaderProcess(false)
    },[]);
    const preloaderProcess = n => {
		let { process } = props;
		//return process(n);
	};
    return (
        <>
        <div className='content'>
            <div className='card'>

            </div>

        </div>


        </>
    )
};
export default BackupData;