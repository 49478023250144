import { Alphabets, Numeric } from '../../Helpers/validation';
export default function formValidate(data) {
//	console.log(data)
	let errs = [];
	//console.log(data);
	// others
	if(data.uid === 0){
		return errs['An unexpected error occurred']
	}
	const others = {
		brv_num: data.brv_num,
		coll_order: data.coll_order,
		compartmentReleased: data.compartmentReleased,
		releasing_tank:data.releasing_tank
	};

	if (!others.brv_num) {
		errs.push('BRV number is required');
	}
	if (!others.coll_order) {
		errs.push('Collection order is required');
	}

	if (!others.compartmentReleased) {
		errs.push('Compartments cannot be empty');
	}
	if (!others.releasing_tank) {
		errs.push('Releasing tank is required');
	}

	//alphabets
	const alpha = {
		product_type: data.product_type,
		driver: data.driver,
		//apd vars
		supervisor: data.supervisor,
		remarks: data.remarks,
		dipper: data.dipper,
		transporter: data.transporter,
		recieving_depot: data.recieving_depot,
		presence_of_water: data.presence_of_water,
		owner: data.owner,
		sealer: data.sealer,
		loading_depot: data.loading_depot,
	};
	if (!alpha.sealer) {
		errs.push('Sealer is required');
	}
	if (!alpha.loading_depot) {
		errs.push('Loading depot is required');
	}
	if (!alpha.owner) {
		errs.push('Owner name is required');
	}
	if (!alpha.presence_of_water) {
		errs.push('Presence of water required');
	}
	if (!alpha.recieving_depot) {
		errs.push('Select receiving depot');
	}
	if (!alpha.transporter) {
		errs.push('Transporter name required');
	}
if (!alpha.dipper) {
		errs.push('Dipper name required');
	}
	if (!alpha.product_type) {
		errs.push('Invalid entry for Product type');
	}
	if (!Alphabets(alpha.remarks)) {
		errs.push('Invalid entry for remarks');
	}
// if (!alpha.driver) {
// 		errs.push('Invalid entry for driver');
// 	}
if (!Alphabets(alpha.supervisor)) {
		errs.push('Invalid entry for Supervisor');
	}
if (!Alphabets(alpha.driver)) {
		errs.push('Invalid entry for Driver');
	}
	//validate nums
	const nums = {
		released_1: data.released_1,
		released_2: data.released_2,
		released_3: data.released_3,
		released_4: data.released_4,
		released_5: data.released_5,
		released_6: data.released_6,
		released_7: data.released_7,
		vcf: data.vcf,
		litre_temp: data.litre_temp,
		litre_density_at_20: data.litre_density_at_20,
		total: data.total,
		volumeAt20: data.volumeAt20,
		//apd vars
		meter_arm_num: data.meter_arm_num,
		hFore: data.hFore,
		hAfter: data.hAfter	,
		volume_obs: data.volume_obs

	};
	

	if (isNaN(nums.hFore)) {
		errs.push('Invalid number format at H-Fore');
	}
	/*if (isNaN(nums.volume_obs)) {
		errs.push('Volume Observed is invalid');
	}else if(nums.volume_obs === 0){
		errs.push('Volume Observed is required');
	}*/
	if (isNaN(nums.hAfter)) {
		errs.push('Invalid number format at H-After');
	}
	 if (!nums.meter_arm_num) {
	 	errs.push(' Meter Arm # is required');
	 }
	 //validate comma seperated values from meter num for numbers
	 const mter = nums.meter_arm_num? nums.meter_arm_num.split(','):false
	if (typeof mter ==='object') {
	 	mter.forEach((m)=>{
	 		if(isNaN(m)){
	 			errs.push('Invalid format at Meter Arm #');

	 		}

	 	})
	 }

	if (!Numeric(nums.released_1)) {
		errs.push('Invalid number format at Compartment 1');
	}

	if (!Numeric(nums.released_2)) {
		errs.push('Invalid number format at Compartment 2');
	}

	if (!Numeric(nums.released_3)) {
		errs.push('Invalid number format at Compartment 3');
	}

	if (!Numeric(nums.released_4)) {
		errs.push('Invalid number format at Compartment 4');
	}

	if (!Numeric(nums.released_5)) {
		errs.push('Invalid number format at Compartment 5');
	}
	if (!Numeric(nums.released_6)) {
		errs.push('Invalid number format at Compartment 6');
	}
	if (!Numeric(nums.released_7)) {
		errs.push('Invalid number format at Compartment 7');
	}
	/*if (isNaN(nums.vcf)) {
		errs.push('Invalid number format at VCF');
	}
	if (isNaN(nums.litre_temp)) {
		errs.push('Invalid number format at L/Temperature');
	}*/
	if (isNaN(nums.litre_density_at_20)) {
		errs.push('Invalid number format at L/Density');
	}
	if (isNaN(nums.total)) {
		errs.push('Invalid number format at Total');
	}
	if (isNaN(nums.volumeAt20)) {
		errs.push('Invalid number format at Volume@20');
	}
	//console.log(errs);
	return errs;
}


export function validateRegistration(){




	
}