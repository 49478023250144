import React, { Component, useRef } from 'react';

import { Link, useNavigate } from 'react-router-dom';
import Users from './Users/Users';
import ReactToPrint from 'react-to-print';
import Waybill from '../Components/Waybill';
import Report from './Report/';
import Preloader from '../Components/preloader/';
import AccessRight from "./Security/"
//import {ReactSVG} from "react-svg"
import { Read } from '../controllers/Read/';
import Approvals from '../Approvals/';
import Notification from '../Notifications';
import BackupData from './Backup';
import { apiURL } from '../urlConfig';
import DashboardLanding from './Dashboard/DashboardLanding'
import { authContext } from '../hooks/use-auth'


const io = require('socket.io-client')


//--------------------------------------------
const socket = io.connect(apiURL,
	{
		forceNew: true,
		reconnectionAttemps: 15,
		reconnectionDelay: 3000
	})
let audio = new Audio(`/sounds/alert1.wav`)
class Dashboard extends Component {
	static contextType = authContext;
	constructor() {
		super();
		this.state = {
			load: true,
			notificationCount: 0,
			notificationData: [],
			approvalNotif: [],
			actionType: 'created',
			isOldNotification: false,
			canPlaySound: false
		};
	}
	saveOldNotifsLocal = (data) => {
		const stateOfNotifs = data?.map((n) => n.brv_num);
		let savedNotifs = JSON.parse(localStorage.getItem('_alertNotifs'));
		if (Array.isArray(savedNotifs)) {
			for (let i = 0; i < stateOfNotifs?.length; i++) {
				if (!savedNotifs.includes(stateOfNotifs[i])) {
					savedNotifs.push(stateOfNotifs[i]);
				}
			}
			localStorage.setItem('_alertNotifs', JSON.stringify(savedNotifs))
		} else {
			localStorage.setItem('_alertNotifs', JSON.stringify(stateOfNotifs))
		}
	}

	componentDidMount = async () => {
		const { user } = this.context
		let canPlaySound = false;
		//fetching approval notifications on mount
		//const { notificationCount } = this.state
		//fetch general notifications
		socket.emit('get-notifications')
		socket.on('notifications', data => {
			//console.log(role)
			if (user.level && user.level === 'approvals') {
				this.setState({
					notificationData: data?.created,
					notificationCount: data.created?.length
				})
				if (data.created?.length > 0) {
					canPlaySound = this.localStorageWorker(data?.created)
					//console.log(canPlaySound,': sound')
					this.saveOldNotifsLocal(data?.created)
					if (canPlaySound) {
						try {
							audio.play()


						} catch (error) {
							throw error
						}

					}
				}
			} else {


				this.setState({
					approvalNotif: data?.approved,
					notificationCount: data.approved?.length
				})
				if (data.approved?.length > 0) {

					canPlaySound = this.localStorageWorker(data?.approved)

					//console.log(canPlaySound,': sound')
					if (canPlaySound) {

						audio.play()

					}
					this.saveOldNotifsLocal(data?.approved)
				}
			}



		})

		socket.on('notify', payload => {
			//console.log(payload)
			//only for approval person
			if (user.level.toLowerCase() === 'approvals') {
				this.setState({ notificationCount: payload?.length, notificationData: payload })

				canPlaySound = this.localStorageWorker(payload)
				if (canPlaySound) {
					audio.play()

				}
				this.saveOldNotifsLocal(payload)
			}

		})
		socket.on('brv-approved', payload => {
			//console.log(payload, 'brv approved')

			if (user.level.toLowerCase() !== 'approvals') {
				this.setState({ approvalNotif: payload })
				canPlaySound = this.localStorageWorker(payload)
				if (canPlaySound) {
					audio.play()

				}
				this.saveOldNotifsLocal(payload)
			}
		})		
	}
	
	componentWillUnmount = () => {
		this.setState({ notificationData: [] })
		audio.pause()
	}
	handlePreloader = n => {
		//let tg = n===true?false:true;
		//console.log(n," process")
		this.setState({ load: n });
	};
	localStorageWorker = (currentNotifs) => {
		//to prevent sound from playing over and over for older notifications on reload
		let d = currentNotifs?.map((g) => g.brv_num)
		let hasNew = false;
		const data = JSON.parse(localStorage.getItem('_alertNotifs'))
		if (Array.isArray(data)) {
			for (let i = 0; i < d?.length; i++) {
				//check if brv # exists in old 
				if (!data?.includes(d[i])) {
					hasNew = true;
					break
				}
			}
			return hasNew
		} else {
			//	console.log('no localstorage data');
			return true
		}


	}
	render() {

		const { load, approvalNotif, notificationCount, notificationData, actionType } = this.state;
		const param = ''
		const { user } = this.context
		return (

			<div className="">
			
				<div className="main-pane">
					{/* <Header showMenu={false} loggedIn={false} count={notificationCount} /> */}
				
					<section className="pane">
						{/* <SideBar current={param} role={user?.role} user={user} /> */}
{/* 
						<MainPane
							src={`${process.env.PUBLIC_URL}/assets/icons/`}
							page={param}
							process={this.handlePreloader}
							role={user.level}

						/> */}
						<DashboardLanding/>
					</section>
				</div>

				{user.level === 'approvals' ?
					notificationData.map((m, i) => (
						<Notification key={i} info={m.brv_num} type={'created'} />
					))
					: null
				}
				{user.level !== 'approvals' ?
					approvalNotif.map((a, i) => (
						<Notification key={i} info={a.brv_num} type={'approved'} />

					))
					: null}
			</div>

		);
	}
}
export default Dashboard;
class Example extends React.PureComponent {
	render() {
		return (
			<div>
				<ReactToPrint
					trigger={() => {
						return <button>Print</button>;
					}}
					content={() => this.componentRef}
				/>
				<Waybill process={this.props.process} ref={el => (this.componentRef = el)} />
			</div>
		);
	}
}
/*
class SideBar extends React.PureComponent {
	render() {

		const { current, role, user } = this.props
		const accessViewSidebar = []
		return (
			<div className="sb-main1">
				<aside className="sidebar-container">
					<div>
						<ProfilePic name={user.fullName} role={role} image={''} />
						<ul>

							{accessViewSidebar.map((a, i) => (
								<SidebarMenuItem key={i} current={current} menuItem={a.menuItem} icon={a.icon} path={a.path} />
							))}
						</ul>
					</div>
				</aside>
			</div>
		);
	}
}*/

//1px solid #4b545c
function ProfilePic({ ...props }) {
	return (
		<>

			<div className="user-panel mt-2 mb-2 d-flex jkuyi8">
				<div className="image pt-10 d-none">
					<img src={`${process.env.PUBLIC_URL}/bostLogo.png`} className="img-circle elevation-2" alt="User Image" />
				</div>
				<div className="info">
					<a href="#" style={{ color: '#c2c7d0' }} className="d-block mb-2 mt-0">{props.name}</a>
					<div style={{ marginTop: '-10px', textTransform: 'capitalize' }}>
						<small className='badge badge-success mt-3px'>{props.role}</small>
					</div>
				</div>

			</div>


		</>
	)
}
function SidebarMenuItem({ current, menuItem, icon, path }) {
	const curr = { background: '#0606bb12' };
	return (
		<>
			<li className='nav-item' >
				<Link to={`/admin/${path}`}>
					<div className={`link-itm`} >
						{/* <img src={`${process.env.PUBLIC_URL}/assets/icons/${icon}`} height="25px" alt="" /> */}
						<i className={`nav-icon ${icon}`} aria-hidden="true"></i>
						<p className='primary'>{menuItem}</p>
					</div>
				</Link>
			</li>


		</>
	);
}
function MainPane({ page, process, src, role }) {
	const pdfRef = useRef();
	const history = useNavigate()


	return (
		<div className="main-bost-pg">
			{page === 'users' && role === "admin" ? (
				<div>
					<Users process={process} />
				</div>
			) : page === 'fan-bill' ? (
				<div>
					<Example ref={pdfRef} process={process} />
				</div>
			) : page === 'records' ? (
				<>
					{role === "admin" || role === "invoicing" || role === "viewer" ?
						<Report process={process} role={role?.toLowerCase()} /> : history.goBack()
					}
				</>

			) : page === 'backup' ? (
				<>
					{role === 'admin' ? <BackupData process={process} /> : null}
				</>
			)



				: page === 'approvals' && role === "approvals" ? (
					<div>
						{role === "approvals" ? <Approvals process={process} /> : history.goBack()}
					</div>
				) : (
					<DashboardLanding role={role} src={src} process={process} />
				)}
		</div>
	);
}

