import React from 'react';
export default function Error({ msg,close }) {
	
	function toggle(){
		return close()
	}
	return (
		<React.Fragment>
			{msg.length > 0?
			<div className="msg-err">
				<button onClick={toggle}>&times;</button>
				{
					msg.map((err,i)=> <div key={i}>{err}</div>)

					}
			</div>: null}
		</React.Fragment>
	);
}
