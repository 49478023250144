import React, { Component } from "react";

import { Read } from "../../controllers/";
//import Read from "../../controllers"
import Header from "../header";
import Error from "../Error/";
import Preloader from "../preloader/";
import formValidate from "./form.validate";
import { FormatDate } from "../../Helpers/Dates";
import axios from "../../config/axios";
import API from "../../controllers/API";
import BDC_OMC from "../BDC-OMC/";
import Create from "../../controllers/Create";
import { apiURL } from "../../urlConfig";
import { authContext } from "../../hooks/use-auth";
import { useNavigate } from "react-router-dom";
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import { IconButton, Button, Box, MenuItem, Paper } from '@material-ui/core'
//-----------------------------------------------------
/**ADDING NEWER FIELDS FOR APD */
//REACT_APP_API=https://apd.bostksidepot.com
//REACT_APP_DEV=http://localhost:3030

const io = require("socket.io-client");

const socket = io.connect(apiURL, {
  forceNew: true,
  reconnectionAttemps: 30,
  reconnectionDelay: 3000,
});


//-----------------------------------------
export default class Form extends Component {
  static contextType = authContext;
  constructor(props) {
    super(props);
    this.state = {
      suggestions: [],
      updateBDC_OMC: "",
      loader: true,
      date: null,
      brv_num: "",
      bdc: "",
      product_type: "",
      vcf: "",
      litre_temp: "",
      litre_density_at_20: "",
      success: false,
      coll_order: "",
      driver: "",
      compartments: [1, 2, 3, 4, 5, 6, 7],
      compQty: 1,

      released_1: 0,
      released_2: 0,
      released_3: 0,
      released_4: 0,
      released_5: 0,
      released_6: 0,
      released_7: 0,
      volumeAt20: 0,
      volume_obs: 0,
      uid: 0,
      email: "",
      requestStatus: null,
      errs: [],
      brvs: [],
      bdcs: [],
      //vars for apd
      supervisor: "",
      remarks: "",
      meter_arm_num: 0,
      hFore: 0,
      dipper: "",
      loading_depot: "APD",
      hAfter: 0,
      sealer: "",
      recall_brv_num: "",
      transporter: "",
      transporters: [],
      recieving_depot: "",
      presence_of_water: "",
      owner: "",
      status: "",
      dip_ullage_1: 0,
      dip_ullage_2: 0,
      dip_ullage_3: 0,
      dip_ullage_4: 0,
      dip_ullage_5: 0,
      dip_ullage_6: 0,
      dip_ullage_7: 0,
      oh_1: 0,
      oh_2: 0,
      oh_3: 0,
      oh_4: 0,
      oh_5: 0,
      oh_6: 0,
      oh_7: 0,
      top_seal_1: "",
      top_seal_2: "",
      top_seal_3: "",
      top_seal_4: "",
      top_seal_5: "",
      top_seal_6: "",
      top_seal_7: "",
      down_seal_1: "",
      down_seal_2: "",
      down_seal_3: "",
      down_seal_4: "",
      down_seal_5: "",
      down_seal_6: "",
      down_seal_7: "",
      recall: false,
      recallableBrvs: [],
      errForbrv: "",
      submitting: false,
      releasing_tank: ''
    };
  }

  componentDidMount = async () => {

    const { user } = this.context;
    if (user) {
      this.setState({ uid: user.uid });
    }
    const d = FormatDate(new Date());
    this.setState({ date: d.date, loader: false });

    //fetch BRV'S

    const brvs = await Read("api/brv");
    const transporters = await Read("api/transporters");
    const bdcs = await Read("api/bdc");
    if (brvs) {
      let v = brvs.map((b) => b.name);
      this.setState({ brvs: v });
    }

    if (transporters) {
      //console.log(transporters)
      let v = transporters.map((b) => b.name);
      this.setState({ transporters: v });
    }
    if (bdcs) {
      let v = bdcs.map((b) => b.name);
      this.setState({ bdcs: v });
    }
    //SET & fetch SUGGESTIONS
    let suggestions = localStorage.getItem("suggestionList");
    if (!suggestions) {
      let sugg = {
        sugg_sealer: [],
        sugg_released: [],
        sugg_supervisor: [],
        sugg_dipper: [],
      };

      localStorage.setItem("suggestionList", JSON.stringify(sugg));
    }
    let suggObj = JSON.parse(localStorage.getItem("suggestionList"));

    this.setState({ suggestions: suggObj });
    //get recallable brv numbers
    const recallable = await Read("api/fan-bill/recall-brv/list");
    if (recallable) {
      let arr = recallable.map((c) => c.brv_num);
      //console.log(arr)

      this.setState({ recallableBrvs: arr });
    } else {
    }
    const curr_operation= await Create('api/user/footprint')
    if(curr_operation){
      return
    }
  };

  ApiCalculateVcf = async () => {
    //chek for both values available
    const { litre_density_at_20, litre_temp } = this.state;
    if (litre_density_at_20 && litre_temp) {
      const post = await Create("api/vcf/xlsx/calculator", {
        D13: litre_density_at_20,
        D14: litre_temp,
      });
      if (post) {
        //console.log(typeof (post.vcf))
        this.setState({ vcf: post.vcf });
      }
    } else {
      //console.info('condition vcf: false')
    }
  };

  handleLoader = (n) => {
    let tgl = this.state.load === true ? false : true;
    this.setState({ load: tgl });
  };
  submitEntry = async (e) => {
    this.setState({ submitting: true });
    e.preventDefault();
    this.setState({ errs: [], success: false, loader: true });
    this.handleLoader(true);
    const { uid } = this.state;
    const comp = {
      r_1: parseFloat(this.state.released_1),
      r_2: parseFloat(this.state.released_2),
      r_3: parseFloat(this.state.released_3),
      r_4: parseFloat(this.state.released_4),
      r_5: parseFloat(this.state.released_5),
      r_6: parseFloat(this.state.released_6),
      r_7: parseFloat(this.state.released_7),
    };
    let totalCompartment = 0;
    for (let v of Object.values(comp)) {
      totalCompartment += v;
    }
    // let vAt20 = parseFloat(this.state.vcf) * totalCompartment;
    let TOTAL_VOLUME_OBS = parseFloat(
      Math.round(totalCompartment / this.state.vcf)
    );
    const date = FormatDate(new Date());
    const {
      dip_ullage_1,
      dip_ullage_2,
      dip_ullage_3,
      dip_ullage_4,
      dip_ullage_5,
      dip_ullage_6,
      dip_ullage_7,
      oh_1,
      oh_2,
      oh_3,
      oh_4,
      oh_5,
      oh_6,
      oh_7,
      top_seal_1,
      top_seal_2,
      top_seal_3,
      top_seal_4,
      top_seal_5,
      top_seal_6,
      top_seal_7,
      down_seal_1,
      down_seal_2,
      down_seal_3,
      down_seal_4,
      down_seal_5,
      down_seal_6,
      down_seal_7,
      volume_obs
    } = this.state;

    const data = {
      date: date.date,
      //	compQty: this.state.compQty,
      released_1: parseFloat(this.state.released_1),
      released_2: parseFloat(this.state.released_2),
      released_3: parseFloat(this.state.released_3),
      released_4: parseFloat(this.state.released_4),
      released_5: parseFloat(this.state.released_5),
      released_6: parseFloat(this.state.released_6),
      released_7: parseFloat(this.state.released_7),
      compartmentReleased: JSON.stringify(comp),
      uid,
      //APD vars
      product_type: this.state.product_type,
      total: volume_obs,
      volume_obs: volume_obs,
      volumeAt20: totalCompartment,
      compartments: this.state.compartments,
      driver: this.state.driver,
      coll_order: this.state.coll_order,
      vcf: parseFloat(this.state.vcf),
      litre_temp: parseFloat(this.state.litre_temp),
      litre_density_at_20: parseFloat(this.state.litre_density_at_20),
      brv_num: this.state.brv_num,
      supervisor: this.state.supervisor,
      remarks: this.state.remarks,
      meter_arm_num: this.state.meter_arm_num,
      hFore: this.state.hFore,
      dipper: this.state.dipper,
      loading_depot: this.state.loading_depot,
      hAfter: this.state.hAfter,
      sealer: this.state.sealer,
      transporter: this.state.transporter,
      recieving_depot: this.state.recieving_depot,
      presence_of_water: this.state.presence_of_water,
      owner: this.state.owner,
      approved_by: this.state.approved_by,
      status: "PENDING",
      dip_ullage_1,
      dip_ullage_2,
      dip_ullage_3,
      dip_ullage_4,
      dip_ullage_5,
      dip_ullage_6,
      dip_ullage_7,
      oh_1,
      oh_2,
      oh_3,
      oh_4,
      oh_5,
      oh_6,
      oh_7,
      top_seal_1,
      top_seal_2,
      top_seal_3,
      top_seal_4,
      top_seal_5,
      top_seal_6,
      top_seal_7,
      down_seal_1,
      down_seal_2,
      down_seal_3,
      down_seal_4,
      down_seal_5,
      down_seal_6,
      down_seal_7,
      releasing_tank: this.state.releasing_tank
    };

    //validate
    const errs = await formValidate(data);
    //double check if value for brv is in the list
    const checkBRV = () => {
      if (this.state.brvs.includes(data.brv_num)) {
        return true;
      }
      errs.push("Please check and enter valid BRV #");
      return false;
    };
    const checkBDC = () => {
      if (this.state.bdcs.includes(data.owner)) {
        return true;
      }
      errs.push("Please check and enter valid  OWNER / BDC");
      return false;
    };

    const checkTRANSPORTER = () => {
      if (this.state.transporters.includes(data.transporter)) {
        return true;
      }
      //errs.push('Please check and enter Transporter')
      return false;
    };

    if (
      errs.length === 0 &&
      checkBRV() === true &&
      checkTRANSPORTER() === true &&
      checkBDC() === true
    ) {
      try {
        //save some values to cache localstorage
        this.suggestionSetterFunc(data);

        const post = await axios.post(`${API()}/api/fan-bill`, data);

        if (post) {
          //console.log(post.data);
          socket.emit("doc-created", { data: "" });

          this.setState({
            success: true,
            requestStatus: "Record added successfully",
            errs: [],
          });
          data["record_date"] = date;
          localStorage.setItem("waybill", JSON.stringify(data));
          this.handleLoader(false);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        } else {
          this.setState({ errs: ["An error occurred "], loader: false });
        }
        this.setState({ submitting: false, loader: false });
      } catch (error) {
        //	console.log(error.response)
        this.setState({ submitting: false, loader: false });
        if (error.response?.status === 403) {
          this.setState({ errs: [error.response.data] });
        } else {
          this.setState({ errs: ["Operation failed"] });
          throw error;
        }
      }
      //this.setState({ submitting: false, loader: false })
    } else {
      //console.log(data)

      this.setState({ submitting: false, errs, loader: false });
    }
    this.setState({ submiting: false });
  };

  addCompartment = () => {
    let { compartments } = this.state;
    if (compartments.length < 7) {
      let x = (compartments.length + 1).toString();
      compartments.push(x);
      this.setState({ compartments });
      //console.log(x);
    }
  };
  compartmentHandler = (m, n) => {
    if (!isNaN(n)) {
      let {
        released_1,
        released_2,
        released_3,
        released_4,
        released_5,
        released_6,
        released_7,

        volumeAt20
      } = this.state;
      let t =
        parseFloat(released_1) +
        parseFloat(released_2) +
        parseFloat(released_3) +
        parseFloat(released_4) +
        parseFloat(released_5) +
        parseFloat(released_6) +
        parseFloat(released_7);
      volumeAt20 = parseFloat(t) + parseFloat(n);
      this.setState({ [m]: n, volumeAt20 });
    } else {
      this.setState({ [m]: n });
    }
  };
  handleSelect = (m, n) => {
    this.setState({ [m]: n });
  };
  handleInput = (m, n) => {
    this.setState({ [m]: n });
  };

  onToggleUpdateBDCOMC = (n) => {
    this.setState({ updateBDC_OMC: n.target.value });
  };
  unsetUpdateOMCBDC = () => {
    this.setState({ updateBDC_OMC: "" });
  };
  closeErr = () => {
    this.setState({ errs: [] });
  };
  closeSucc = () => {
    this.setState({ success: false });
  };
  calcFields = (v) => {
    if (!isNaN(v)) {
      return v.toString();
    } else {
      return "0";
    }
  };
  refreshData = (data, t) => {
    let p = "";
    if (t === "brv") {
      p = "brvs";
    } else if (t === "transporter") {
      p = "transporters";
    } else if (t === "bdc") {
      p = "bdcs";
    }
    this.setState({ [p]: data });
  };
  onFocus = async () => {
    //on focus of brv # update the list of brv's to fetch newer at the time of entry
    const brvs = await Read("api/brv");
    if (brvs) {
      let v = brvs.map((b) => b.name);
      this.setState({ brvs: v });
    }
  };

  onHandleRecall = () => {
    if (this.state.recallableBrvs?.length > 0) {
      if (this.state.recallableBrvs.includes(this.state.recall_brv_num)) {
        this.setState({ recall: true });
        if (this.state.recall_brv_num !== "") {
          sessionStorage.setItem('_recall_brv', this.state.recall_brv_num);
          console.log(this.props);

          /*
          this.props.history.push(
            "/brv-recall/" + this.state.recall_brv_num?.replace(/\s/g, "_"),
            { brv: this.state.recall_brv_num.toUpperCase().replace(/\s/g, "_") }
          );*/
        }
      } else {
        this.setState({
          errs: ["Please select available BRV's from the options to update"],
        });
      }
    }
  };

  handleRecallInput = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  showSettingsMenu = () => {
    this.setState({ openSettings: !this.state.openSettings })
  }
  omcSelector = () => {
    sessionStorage.setItem('__apd_operation_type', 'omc-loading')
    this.setState({ openSettings: !this.state.openSettings })
    return process.env.NODE_ENV === 'production' ? (window.location.href = 'https://omc.bostmonitor.com') : (window.location.href = 'http://localhost:3001')
  }
  render() {
    const {
      recall_brv_num,
      suggestions,
      success,
      updateBDC_OMC,
      errs,
      loader,
      compartments,
      brvs,
      bdcs,
      date,
      vcf,
      transporters,
    } = this.state;
    const {
      released_1,
      released_2,
      released_3,
      released_4,
      released_5,
      released_6,
      released_7,
    } = this.state;
    //console.log(vcf);

    let TOTAL_COMPARTMENT_RELEASED =
      parseFloat(released_1) +
      parseFloat(released_2) +
      parseFloat(released_3) +
      parseFloat(released_4) +
      parseFloat(released_5) +
      parseFloat(released_6) +
      parseFloat(released_7);
    //old formular
    // let vAt20 = Math.round(parseFloat(vcf) * TOTAL_COMPARTMENT_RELEASED);
    //new calculation
    let $vAt20 = parseFloat(TOTAL_COMPARTMENT_RELEASED);
    let TOTAL_VOLUME_OBS = Math.round(parseFloat($vAt20 / vcf));
    //	console.log(TOTAL_VOLUME_OBS,'',vcf);

    return (
      <div>
        <Header showLogout={false} />
        <Preloader load={loader} />

        {errs.length > 0 ? <Error msg={errs} close={this.closeErr} /> : null}
        {success ? (
          <div className="success-msg">
            Record added successfully{" "}
            <button onClick={this.closeSucc} id="suc-cls-btn">
              {" "}
              &times;{" "}
            </button>
          </div>
        ) : null}

        <div className="gh6Ty flex">
          <div className="btn-group btn-group-toggle">
            <button
              className="btn btn-info btn-flat"
              value="TRANSPORTERS"
              onClick={this.onToggleUpdateBDCOMC}
            >
              {" "}
              TRANSPORTERS
            </button>
            <button
              className="btn btn-info btn-flat"
              value="BRV"
              onClick={this.onToggleUpdateBDCOMC}
            >
              {" "}
              BRV #
            </button>{" "}
            <button
              className="btn btn-info btn-flat"
              value="BDC"
              onClick={this.onToggleUpdateBDCOMC}
            >
              {" "}
              OWNER / BDC
            </button>


            <RecallButton recallableBrvs={this.state.recallableBrvs} recall_brv_num={this.state.recall_brv_num} />
          </div>
          {this.state.recallableBrvs?.length > 0 ? (
            <input
              className="mt-10"
              name="recall_brv_num"
              value={recall_brv_num}
              onChange={this.handleRecallInput}
              placeholder="Enter BRV # to edit"
              style={{ paddingLeft: 10, paddingRight: 10 }}
              list="recall_brv_num"
            />
          ) : null}{" "}
          {this.state.recallableBrvs.length > 0 ? (
            <datalist disabled id="recall_brv_num">
              {this.state.recallableBrvs.map((d, i) => (
                <option key={i} value={d} />
              ))}
            </datalist>
          ) : null}

          <IconButton onClick={this.showSettingsMenu}>
            <SettingsOutlinedIcon />
          </IconButton>

          <Box>

            {this.state.openSettings &&
              <Box style={{ width: '160px', height: 50, position: 'absolute' }}>
                <Paper>
                  <MenuItem
                  disabled
                    onClick={
                      () => this.setState({ openSettings: !this.state.openSettings })
                    }>Inter-Depot Loading</MenuItem>
                  <MenuItem
                    onClick={this.omcSelector}
                  >OMC Loading</MenuItem>
                </Paper>

              </Box>}
          </Box>
        </div>

        <div>
          <small className="badge badge-warning">{this.state.errForbrv}</small>
        </div>
        {updateBDC_OMC !== "" ? (
          <BDC_OMC
            unmount={this.unsetUpdateOMCBDC}
            action={updateBDC_OMC}
            refreshData={this.refreshData}
          />
        ) : null}

        <form
          onSubmit={this.submitEntry}
          className="form-grp"
          autoComplete="off"
        >
          <div className="fm-main-field">
            {
              <div className="fm-part-group ">
                <div className="fieldEl">
                  <div>
                    <label className="badge badge-info">DATE </label>
                  </div>
                  {date === null ? null : (
                    <input type="text" name="date" value={date} disabled />
                  )}
                </div>
                <Input
                  type="text"
                  name="driver"
                  lbl="DRIVER"
                  handler={this.handleInput}
                />
                <Select
                  type="text"
                  name="product_type"
                  lbl="PRODUCT TYPE"
                  data={["AGO", "PMS", "PMS-95"]}
                  handler={this.handleInput}
                />
                <div style={{ display: "none" }}>
                  <Input
                    disabled={true}
                    readOnly
                    type="text"
                    name="vcf"
                    value={vcf}
                    lbl="VCF"
                    handler={this.handleInput}
                  />
                </div>
                <InputSuggest
                  type="text"
                  name="supervisor"
                  lbl="SUPERVISOR"
                  data={suggestions.sugg_supervisor || []}
                  handler={this.handleSelect}
                  foc={this.onFocus}
                />
                <div className="fieldEl" style={{}}>
                  {/* VOLUME@20 CHANGED TO TOTAL OR VOLUME_OBSERVED  ON 17/10 PS: CALCULATION CHANGED*/}
                  <div>
                    <label className="badge badge-info">VOLUME OBS. </label>
                  </div>
                  {
                    <input
                      type="text"
                      name="volume_obs"
                      onChange={(e) => {
                        this.handleInput(e.target.name, e.target.value);
                      }}
                    />
                  }
                </div>
              </div>
            }

            {
              <div className="fm-part-group ">
                <Input
                  type="text"
                  name="coll_order"
                  lbl="COLLECTION ORDER #"
                  handler={this.handleInput}
                />
                <Input
                  type="text"
                  name="meter_arm_num"
                  lbl="METER / ARM #"
                  handler={this.handleInput}
                />

                <Input
                  type="text"
                  name="hFore"
                  lbl="C.H-FORE (CM)"
                  handler={this.handleInput}
                />
                {/* <Input type="text" name="dipper" lbl="DIPPER" handler={this.handleInput} /> */}
                <InputSuggest
                  type="text"
                  name="dipper"
                  lbl="DIPPER"
                  handler={this.handleInput}
                  data={suggestions?.sugg_dipper || []}
                />
                {/* <>
              
                    {/*\ PREVIOUSLY TOTAL CHANGED TO VOLUME@20 ON 17/10/2021 *\/}

                 */}
                <div className="fieldEl" title="Dynamically generated values">
                  <div>
                    <label className="badge badge-info">VOLUME@20 </label>
                  </div>
                  {
                    <input
                      type="text"
                      name="volumeAt20"
                      placeholder={isNaN($vAt20) ? "0" : $vAt20.toString()}
                      value={this.calcFields($vAt20)}
                      readOnly
                      disabled
                    />
                  }
                </div>
              </div>
            }
            {/**end of col 2 */}
            {
              <div className="fm-part-group">
                <div>
                  <InputSuggest
                    type="text"
                    name="brv_num"
                    lbl="BRV #"
                    data={brvs}
                    handler={this.handleSelect}
                    foc={this.onFocus}
                  />
                </div>
                <div className="fm-part-group ">
                  <div className="fieldEl" title="Loading depot locked to APD">
                    <div>
                      <label className="badge badge-info">LOADING DEPOT</label>
                    </div>

                    {
                      <select name="loading_depot" disabled readOnly>
                        <option value="APD">APD</option>
                      </select>
                    }
                  </div>
                </div>
                <div style={{}}>
                  <Input
                    type="text"
                    name="litre_temp"
                    lbl="L/TEMPERATURE (°C)"
                    handler={this.handleInput}
                  />
                </div>
                <Input
                  type="text"
                  name="hAfter"
                  lbl="C.H-AFTER (CM)"
                  handler={this.handleInput}
                />
                {/* <Input type="text" name="sealer" lbl="SEALER" handler={this.handleInput} /> */}
                <InputSuggest
                  type="text"
                  name="sealer"
                  lbl="SEALER"
                  handler={this.handleInput}
                  data={
                    suggestions !== undefined ? suggestions?.sugg_sealer : []
                  }
                />
                <Input
                  type="text"
                  name="releasing_tank"
                  lbl="RELEASING TANK"
                  handler={this.handleInput}
                />
              </div>
            }
            {/**end of col 3 */}
            {
              <div className="fm-part-group">
                <div>
                  <InputSuggest
                    type="text"
                    name="transporter"
                    lbl="TRANSPORTER"
                    handler={this.handleInput}
                    data={transporters}
                  />
                </div>
                <Select
                  type="text"
                  name="recieving_depot"
                  lbl="RECEIVING DEPOT"
                  data={["AKOSOMBO", "BUIPE", "KUMASI"]}
                  handler={this.handleSelect}
                />
                <Input
                  type="text"
                  name="litre_density_at_20"
                  lbl="L/DENSITY@20 (g/ml)"
                  handler={this.handleInput}
                  onBlur={this.ApiCalculateVcf}
                />
                <Select
                  type="text"
                  name="presence_of_water"
                  lbl="PRESENCE OF WATER"
                  data={["YES", "NO"]}
                  handler={this.handleSelect}
                />
                {/*releasing_tank {<Input type="text" name="owner" lbl="OWNER" handler={this.handleInput} />} */}

                <InputSuggest
                  type="text"
                  name="owner"
                  lbl="BDC / OWNER"
                  data={bdcs}
                  handler={this.handleSelect}
                  foc={this.onFocus}
                />
                <Input
                  type="text"
                  name="remarks"
                  lbl="REMARKS"
                  handler={this.handleInput}
                />

              </div>
            }
          </div>
          <div className="yuOp2">
            <button
              className="submitBtn"
              disabled={this.state.submitting}
              type="submit"
            >
              {this.state.submitting ? (
                <i className="fa fa-spinner fa -spin"></i>
              ) : (
                "Submit"
              )}
            </button>
          </div>
          <h3 className="hdrQ2">ENTER DIPPING INFORMATION</h3>
          {compartments.map((c, i) => (
            <CompartmentX
              key={i}
              keyx={i + 1}
              type="text"
              name="compartment"
              handler={this.compartmentHandler}
              lbl="COMPARTMENT"
            />
          ))}
          {/*<div className="fieldEl" onClick={this.addCompartment}>
						<button type="button" className="hint-btn">
							+
						</button>
						<span className="hint-label">Add more compartments</span>
					</div>*/}
        </form>
        <div style={{ height: "300px" }}></div>
      </div>
    );
  }
  suggestionSetterFunc = (data) => {
    let sugg = {
      sugg_sealer: [],
      sugg_released: [],
      sugg_supervisor: [],
      sugg_dipper: [],
    };

    let suggList = JSON.parse(localStorage.getItem("suggestionList"));
    if (typeof suggList === "object" && suggList) {
      //alert('run')
      //first check if value exists
      sugg = suggList;
      const isExists = (list, key, val) => {
        if (typeof list === "object" && !list.includes(val)) {
          suggList[key].push(val);
        }

        return list;
      };

      isExists(suggList.sugg_sealer, "sugg_sealer", data.sealer);
      isExists(suggList.sugg_released, "sugg_released", data.released_1);
      isExists(suggList.sugg_supervisor, "sugg_supervisor", data.supervisor);
      isExists(suggList.sugg_dipper, "sugg_dipper", data.dipper);
      //sugg.sugg_sealer.push('gfg')
      localStorage.setItem("suggestionList", JSON.stringify(sugg));
    } else {
      //set initial values

      sugg = {
        sugg_sealer: [data.sealer],
        sugg_released: [data.released],
        sugg_supervisor: [data.supervisor],
        sugg_dipper: [data.dipper],
      };
      localStorage.setItem("suggestionList", JSON.stringify(sugg));
    }
  };
}
const handleCacheRelease = (e) => {
  const releaseSuggestions = JSON.parse(
    localStorage.getItem("releaseSuggestions")
  );

  const val = e.target.value;
  //console.log(e.target.value,"hooking release val 🎈🎈🎈", releaseSuggestions, typeof null)

  if (typeof releaseSuggestions === "object" && releaseSuggestions) {
    if (!releaseSuggestions.includes(val)) {
      releaseSuggestions.push(val);
    }
    localStorage.setItem(
      "releaseSuggestions",
      JSON.stringify(releaseSuggestions)
    );
  } else {
    //set new

    localStorage.setItem("releaseSuggestions", JSON.stringify([val]));
  }
};

function Input({ name, lbl, type, handler, read, vcfCalc, ...rest }) {
  function inputHandler(e) {
    return handler(e.target.name, e.target.value);
  }
  return (
    <div className="fieldEl">
      <div>
        <label className={read === true ? `badge badge-info` : ""}>{lbl}</label>
        {name === "vcf" ? (
          <button
            className="badge badge-info ml-20"
            //onClick={vcfCalc}
            style={{ marginLeft: 10 }}
            type="button"
          >
            calculate
          </button>
        ) : null}
      </div>
      <input type={type} name={name} onChange={inputHandler} {...rest} />
    </div>
  );
}

function InputSuggest({ name, lbl, type, handler, data, read, foc }) {
  function inputHandler(e) {
    return handler(e.target.name, e.target.value);
  }

  return (
    <>
      <div className="fieldEl">
        <div>
          <label className={read ? `badge badge-info` : ""}>{lbl}</label>
        </div>

        {data && data.length > 0 ? (
          <input
            type={type}
            name={name}
            onChange={inputHandler}
            onFocus={foc}
            list={`${name}`}
          />
        ) : name === "supervisor" || name === "sealer" || name === "dipper" ? (
          <input
            type={type}
            name={name}
            onChange={inputHandler}
            onFocus={foc}
            list={`${name}`}
          />
        ) : (
          <i className="fas fa-spinner fa-spin" aria-hidden="true"></i>
        )}
      </div>

      <datalist className="dUdj5" id={`${name}`}>
        {data && data.map((d, i) => <option key={i} value={d} />)}
      </datalist>
    </>
  );
}

function Select({ name, lbl, type, data, handler, read }) {
  function selectHandler(e) {
    return handler(e.target.name, e.target.value);
  }
  return (
    <div className="fieldEl">
      <div>
        <label className={read ? `badge badge-info` : ""}>{lbl}</label>
      </div>

      <select name={name} onChange={selectHandler}>
        <option value="">SELECT</option>
        {data.map((d, i) => (
          <option key={i} value={d}>
            {d}
          </option>
        ))}
      </select>
    </div>
  );
}
function CompartmentX({ name, lbl, type, keyx, handler }) {
  const onHandleCompartment = (e) => {
    return handler(e.target.name, e.target.value);
  };

  return (
    <div className="tblEl fieldEl splitEl ">
      <div className="comp">
        <div>
          <label>COMPARTMENT</label>
        </div>
        <input
          type={type}
          defaultValue={keyx}
          name={`${name}${keyx}`}
          disabled
        />
      </div>

      <ReleaseInput
        type="text"
        keyx={keyx}
        name={`released_${keyx}`}
        change={onHandleCompartment}
      />
      <div className="dynBoxCover">
        <div>
          <label>&nbsp;DIP/ULLAGE (CM)</label>
        </div>
        <input
          type={type}
          name={`dip_ullage_${keyx}`}
          onChange={onHandleCompartment}
        />
      </div>
      <div className="dynBoxCover">
        <div>
          <label>&nbsp;O.H (CM)</label>
        </div>
        <input type={type} name={`oh_${keyx}`} onChange={onHandleCompartment} />
      </div>
      <div className="dynBoxCover">
        <div>
          <label>&nbsp;TOP SEAL #</label>
        </div>
        <input
          type={type}
          name={`top_seal_${keyx}`}
          onChange={onHandleCompartment}
        />
      </div>
      <div className="dynBoxCover">
        <div>
          <label>&nbsp;DOWN SEAL #</label>
        </div>
        <input
          type={type}
          name={`down_seal_${keyx}`}
          onChange={onHandleCompartment}
        />
      </div>
    </div>
  );
}
function ReleaseInput({ type, name, change, keyx, data }) {
  let releaseSuggestions = JSON.parse(
    localStorage.getItem("releaseSuggestions")
  );

  const handleCacheRelease = (e) => {
    releaseSuggestions = JSON.parse(localStorage.getItem("releaseSuggestions"));

    const val = e.target.value;
    //console.log(e.target.value,"hooking release val 🎈🎈🎈", releaseSuggestions, typeof null)

    if (typeof releaseSuggestions === "object" && releaseSuggestions) {
      if (!releaseSuggestions.includes(val)) {
        releaseSuggestions.push(val);
      }
      localStorage.setItem(
        "releaseSuggestions",
        JSON.stringify(releaseSuggestions)
      );
    } else {
      //set new

      localStorage.setItem("releaseSuggestions", JSON.stringify([val]));
    }
  };

  return (
    <>
      <div className="dynBoxCover">
        <div>
          <label>&nbsp;RELEASED</label>
        </div>
        <input
          type={type}
          name={name}
          onChange={change}
          onBlur={handleCacheRelease}
          list={`${name}`}
        />
      </div>
      <datalist id={`${name}`}>
        {releaseSuggestions &&
          releaseSuggestions.map((d, i) => <option key={i} value={d} />)}
      </datalist>
    </>
  );
}

function RecallButton({ recallableBrvs, recall_brv_num }) {
  //onHandleRecall
  const navigate = useNavigate()
  const onClick = () => {
    sessionStorage.setItem('_recall_brv', recall_brv_num);
    navigate("/app/brv-recall/" + recall_brv_num?.replace(/\s/g, "_"),
      { brv: recall_brv_num.toUpperCase().replace(/\s/g, "_") })
  }
  return (
    <>
      <button
        className="btn btn-secondary btn-flat"
        onClick={onClick}
      >
        {recallableBrvs?.length > 0
          ? "RECALL"
          : "NO AVAILABLE BRV TO UPDATE"}
      </button>
    </>
  )
}