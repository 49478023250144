import React from 'react'
import { Outlet, Navigate } from 'react-router-dom'
import { useAuth } from '../../hooks/use-auth'
//--------------------------------------------

export default function FallbackGuard() {
    const { user } = useAuth();
    //console.log(user,'auth')
    const opr_type = sessionStorage.getItem('__apd_operation_type')

    return (
        <>

            {
                user &&
                <>
                    {/* role authorization redirection */}
                    {user.level === 'user' && <Navigate to='/app' replace />}
                    {user.level === 'admin' && <Navigate to='/admin' replace />}
                    {user.level === 'invoicing' && <Navigate to='/admin' replace />}
                    {user.level === 'viewer' && <Navigate to='/admin' replace />}
                    {user.level === 'approvals' && <Navigate to='/admin/approvals' replace />}
                    

                </>
            }


            {/*
            opr_type && opr_type === 'omc-loading' && designationHandler()
        */}

            <Outlet />
        </>
    )
}

const designationHandler = () => {
    return process.env.NODE_ENV === 'production' ? (window.location.href = 'https://omc.bostmonitor.com') : (window.location.href = 'https://localhost:3001')
}