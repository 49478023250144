import React, { Component } from 'react'
import {Link} from "react-router-dom"
import {ReactSVG} from "react-svg"
import {Select as TSelect,Input} from "../../Components/Elements/form-input/input"
import {Alphabets,Email,Numeric} from "../../Helpers/validation"
import Axios from 'axios';
import API from "../../controllers/API"

import {logOut} from "../../Auth"
import Preloader from "../../Components/preloader"
import Error from "../../Components/Error"
import {Box,Select,MenuItem,TextField} from '@material-ui/core'
import {authContext} from '../../hooks/use-auth'


//----------------------------------------------------------
export default class Create extends Component {
	static contextType=authContext
	constructor(){
		super()
		this.state={
			fullName:'',
			email:'',
			password:'',
			level:'',
			contact:"",
			verified:0,
			depot:'',
			errs:[],
			load:true
			

		}
	}
	componentDidMount=async()=>{
		this.setState({load:false})
	}
	handler=(m,n)=>{
		this.setState({[m]:n})
		//console.log(m,n)
	}
	onChange=(e)=>{
		this.setState({[e.target.name]: e.target.value})
	}
	onRegister=async(e)=>{
		this.setState({load:true})
		e.preventDefault()
		let errs=[]
		//console.log(this.state)
		const {fullName,email,password,level,contact,verified,depot}=this.state;
		const data = { fullName, email, password, level, contact,verified,depot };
		if(!Alphabets(fullName)){
			errs.push("Invalid name")
		}
		if(!Email(email)){
			errs.push("Invalid email string")
		}
		if(!password){
			errs.push("Password is required")
		}
		if(password.length < 8){
			errs.push("Password must be at least 8 characters long ")
		}
		if (!level || level==="") {
			errs.push('User level permission is required')
		}
		if(!Numeric(contact) && !contact.length <10){
			errs.push("Invalid contact number")
		}
		this.setState({ errs: errs });
		if(errs.length ===0){
			try {
				const post = await Axios.post(API()+'/api/user/register',data);
				if(post){
					alert("User added successfully")
					this.setState({load:false})
					window.location.href="/admin/users"
				}
			} catch (error) {
				
				if(error.response?.status === 403){
					alert("Sorry email unavailable !")
					this.setState({load:false})
				}
				else if(error.response?.status === 401){
					logOut()
				}
				else{
				alert('User registration failed');
				this.setState({load:false})
				}
			}
		}else{
			this.setState({load:false})
		}
		
	}
    render() {
		const {errs,load}=this.state
		//console.log(errs);
		const {user} = this.context;
        return (
			<div>
				
				<Preloader load={load}/>
				{
					errs.length > 0?<Error msg={errs}/> :null
					}
				
				<div>
					<Link to="/admin/users" style={{position:'absolute'}}>
						{/* <div className="nav-back" style={{ float: 'left' }}> */}
						{/* 	<ReactSVG src={`${process.env.PUBLIC_URL}/assets/icons/025-left-arrow.svg`} /> */}
						{/* </div> */}
						<button className='btn'>
							<span>
								<i className='fas fa-arrow-left'></i>
							</span>
						</button>
					</Link>
					<h4 style={{ textAlign: 'center', color: '#333' }}>Add New User</h4>
				</div>
				
				<form className="form-grp" onSubmit={this.onRegister} style={{marginTop:10}}>
					<div className="fm-main-field">
						<div className="fm-part-group ">
							<Input type="text" name="fullName" lbl="Full Name" handler={this.handler} />
							<Input type="text" name="email" lbl="Email" handler={this.handler} />
							<Input type="text" name="contact" lbl="Contact" handler={this.handler} />
						</div>
						<div className="fm-part-group ">
							<TSelect lbl="Level" name="level" data={['Admin','Approvals','Invoicing','Viewer', 'User']} handler={this.handler} />
						
							<TextField
								select
								name='depot'
								label='Depot'
								value={this.state.depot}
								onChange={this.onChange}
								fullWidth
								variant='outlined'
							>
								{user&&user.depot===1&&
									[{id:'1',name:'APD'},{id:'5',name:'AKOSOMBO'},{id:'3',name:'BOLGA'},{id:'4',name:'BUIPE'}, {id:'2',name:'KUMASI'},{id:'6',name:'MAMIWATER'}].map((d,i)=>(
										<MenuItem value={d.id} key={i}>
										{d.name}
										</MenuItem>
										))
								}
							</TextField>

							<Input type="password" name="password" lbl="password" handler={this.handler} />

							<div className="yuOp2">
								<button className="submitBtn" type="submit">
									Create Account
								</button>
							</div>
						</div>
					</div>
				</form>
			</div>
		);
    }
}
