import React, { useEffect, useState, useContext, createContext } from 'react';
import httpAxios from '../config/axios'
import redirect from '../redirects';
//import allowedRoutesForRole from './allowedRoutesForRole'
//------------------------------------------------
export const authContext = createContext();

export function ProvideAuth({ children }) {
    const auth = useProvideAuth();
    return <authContext.Provider value={auth}>{children}</authContext.Provider>
}

export const useAuth = () => {
    return useContext(authContext)
}
function useProvideAuth() {

    const [user, setUser] = useState(null);
    const [errors, setErrors] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [authorizedRoutes, setAuthorizedRoutes] = useState([])
    //useEffect
    useEffect(() => {
        verifyUser()
    }, [])
    //add signin function
    const signout = () => {
        return httpAxios.post('api/user/logout')
            .then(res => {
                redirect()
            })
    }

    const verifyUser = () => {
        setErrors([])
        setIsLoading(true)
        httpAxios.post(`api/user/verify`)
            .then(res => {
                setIsLoading(false)
                if (res.status === 200) {
                    const user = res.data.user;
                    user['level'] = user.level.toLowerCase()
                    setUser(user)
                }

            }).catch(error => {
                setErrors(['operation failed!'])
                setIsLoading(false)
            })
    }

    //return user object and auth methods
    return {
        user,
        signout,
        errors,
        isLoading
    }
}